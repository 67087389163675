<block-ui>
  <div class="row">
    <div
      [ngClass]="{
        'col-7':
          !!isUpdate &&
          (!!billForm.value.billPdf || !!billForm.value.proofOfPayment),
        'col-12':
          (!billForm.value.billPdf &&
            !billForm.value.proofOfPayment &&
            !!isUpdate) ||
          !isUpdate
      }"
    >
      <form [formGroup]="billForm">
        <div class="px-1 pt-1 pb-0 gallery-small">
          <div class="align-self-center">
            <label class="font-weight-bold mb-0 mr-1">ID documento</label>
          </div>

          <div class="align-self-center">
            <h5 class="mb-0" *ngIf="!editingDocumentID && !!isUpdate">
              {{ bill.documentNumber }}
            </h5>
            <div *ngIf="!isUpdate">
              <input
                type="text"
                class="form-control"
                formControlName="documentNumber"
                placeholder=""
                [ngClass]="{
                  'is-invalid': submitted && formControls.documentNumber.errors
                }"
              />
            </div>
            <small
              class="form-text text-muted danger invalid-feedback"
              *ngIf="submitted && formControls.documentNumber.errors"
            >
              <div *ngIf="formControls.documentNumber.errors.required">
                El número de documento es obligatorio
              </div>
            </small>
          </div>

          <div class="align-self-center">
            <label
              class="font-weight-bold mb-0 align-self-center mr-1"
            >Fecha de emisión</label
            >
          </div>

          <div class="align-self-center">
            <h5 class="mb-0" *ngIf="!editingStartDate && !!isUpdate">
              {{ bill.startDate }}
            </h5>
            <div *ngIf="!isUpdate">
              <input
                class="form-control"
                type="date"
                formControlName="startDate"
                [ngClass]="{
                  'is-invalid': submitted && formControls.startDate.errors
                }"
              />
            </div>
            <small
              class="form-text text-muted danger invalid-feedback"
              *ngIf="submitted && formControls.startDate.errors"
            >
              <div *ngIf="formControls.startDate.errors.required">
                La fecha de emisión es obligatorio
              </div>
            </small>
          </div>

          <div class="align-self-center">
            <label
              class="font-weight-bold mb-0 align-self-center mr-1"
            >Proveedor</label
            >
          </div>

          <div class="align-self-center">
            <h5 class="mb-0" *ngIf="!editingProvider && !!isUpdate">
              {{ bill.provider }}
            </h5>
            <div *ngIf="!isUpdate">
              <input
                type="text"
                (change)="handleFindPurchaseOrder()"
                class="form-control"
                formControlName="provider"
                placeholder="11.111.111-1 Nombre"
                [ngClass]="{
                  'is-invalid': submitted && formControls.provider.errors
                }"
              />
            </div>
            <small
              class="form-text text-muted danger invalid-feedback"
              *ngIf="submitted && formControls.provider.errors"
            >
              <div *ngIf="formControls.provider.errors.required">
                El proveedor es obligatorio
              </div>
            </small>
          </div>

          <div *ngIf="!!billForm.value.project" class="align-self-center">
            <label
              class="font-weight-bold mb-0 align-self-center mr-1"
            >Proyecto o Centro de costos</label
            >
          </div>

          <div *ngIf="!!billForm.value.project" class="align-self-center">
            <h5
              *ngIf="hasPurchaseOrder && !billForm.value.project"
              class="text-black-50 mb-0"
            >
              Seleccione una OC
            </h5>
            <h5 class="mb-0" *ngIf="!!billForm.value.project">
              {{
              !billForm.value.project.name
                ? (billForm.value.project | doc | async)?.name
                : billForm.value.project.name
              }}
            </h5>
            <h5 *ngIf="!hasPurchaseOrder && !billForm.value.project">
              Proyecto no asignado
            </h5>
          </div>

          <div
            *ngIf="!!billForm.value.projectCategory"
            class="align-self-center"
          >
            <label
              class="font-weight-bold mb-0 align-self-center mr-1"
            >Categoría</label
            >
          </div>

          <div *ngIf="!!billForm.value.projectCategory" class="align-self-center">
            <h5 *ngIf="hasPurchaseOrder && !billForm.value.projectCategory" class="text-black-50 mb-0">Seleccione una
              OC</h5>
            <h5
              class=" mb-0" *ngIf="!!billForm.value.projectCategory &&
              !!billForm.value.projectCategory"
            >
              {{!billForm.value.projectCategory?.name ? (billForm.value.projectCategory | doc | async)?.name : billForm.value.projectCategory.name}}</h5>
            <h5 *ngIf="!!emptyCategory">Categoría no asignada</h5>
          </div>
        </div>

        <div class="d-flex justify-content-center my-1">
          <span
            class="badge badge-pill mr-2"
            [ngClass]="{
              'badge-success': billForm.value.isUsed,
              'badge-primary': !billForm.value.isUsed
            }"
            ngbTooltip="{{
              (billForm.value.isUsed && 'Clasificada') ||
                (!billForm.value.isUsed && 'No clasificada')
            }}"
          >
            <i
              [ngClass]="{
                'la-check': billForm.value.isUsed,
                'la-close': !billForm.value.isUsed
              }"
              class="la"
            ></i>
          </span>
          <span
            class="badge badge-pill"
            [ngClass]="{
              'badge-info': billForm.value.isPaid,
              'badge-primary': !billForm.value.isPaid
            }"
            ngbTooltip="{{ billForm.value.isPaid ? 'Pagada' : 'No pagada' }}"
          >
            <i class="la la-money"></i>
          </span>
        </div>

        <mat-tab-group>
          <mat-tab label="Detalles">
            <div class="p-2 gallery mb-2">
              <div>
                <div class="form-group mb-1">
                  <label class="font-weight-bold mb-0">Valor neto</label>
                  <h5 *ngIf="!editingNetValue && !!isUpdate">
                    ${{ bill.netValue }}
                  </h5>
                  <div *ngIf="editingNetValue">
                    <input
                      type="text"
                      class="form-control"
                      formControlName="netValue"
                      [(ngModel)]="bill.netValue"
                      placeholder=""
                      [ngClass]="{
                        'is-invalid': submitted && formControls.netValue.errors
                      }"
                    />
                  </div>
                  <div *ngIf="!isUpdate">
                    <input
                      type="text"
                      class="form-control"
                      formControlName="netValue"
                      placeholder=""
                      [ngClass]="{
                        'is-invalid': submitted && formControls.netValue.errors
                      }"
                    />
                  </div>
                  <small
                    class="form-text text-muted danger invalid-feedback"
                    *ngIf="submitted && formControls.netValue.errors"
                  >
                    <div *ngIf="formControls.netValue.errors.required">
                      El valor neto es obligatorio
                    </div>
                  </small>
                </div>
              </div>

              <div>
                <div class="form-group mb-1">
                  <label class="font-weight-bold mb-0">IVA</label>
                  <h5 *ngIf="!editingIva && !!isUpdate">${{ bill.iva }}</h5>
                  <div *ngIf="editingIva">
                    <input
                      type="text"
                      class="form-control"
                      formControlName="iva"
                      [(ngModel)]="bill.iva"
                      placeholder=""
                      [ngClass]="{
                        'is-invalid': submitted && formControls.iva.errors
                      }"
                    />
                  </div>
                  <div *ngIf="!isUpdate">
                    <input
                      type="text"
                      class="form-control"
                      formControlName="iva"
                      placeholder=""
                      [ngClass]="{
                        'is-invalid': submitted && formControls.iva.errors
                      }"
                    />
                  </div>
                  <small
                    class="form-text text-muted danger invalid-feedback"
                    *ngIf="submitted && formControls.iva.errors"
                  >
                    <div *ngIf="formControls.iva.errors.required">
                      El iva es obligatorio
                    </div>
                  </small>
                </div>
              </div>

              <div class="form-group">
                <label class="font-weight-bold mb-0">Total *</label>
                <h5 *ngIf="!editingTotal && !!isUpdate">{{ bill.total | clearCurrency : false | swapCommasAndDots }}</h5>
                <div *ngIf="editingTotal">
                  <input
                    type="text"
                    class="form-control"
                    formControlName="total"
                    [(ngModel)]="bill.total"
                    placeholder=""
                    [ngClass]="{
                      'is-invalid': submitted && formControls.total.errors
                    }"
                  />
                </div>
                <div *ngIf="!isUpdate">
                  <input
                    type="text"
                    class="form-control"
                    formControlName="total"
                    placeholder=""
                    [ngClass]="{
                      'is-invalid': submitted && formControls.total.errors
                    }"
                  />
                </div>
                <small
                  class="form-text text-muted danger invalid-feedback"
                  *ngIf="submitted && formControls.total.errors"
                >
                  <div *ngIf="formControls.total.errors.required">
                    El total es obligatorio
                  </div>
                </small>
              </div>

              <div class="form-group" *ngIf="!!editingModal || !isUpdate">
                <label class="font-weight-bold mb-0">PDF de la Factura</label>
                <div class="custom-file">
                  <input
                    type="file"
                    class="custom-file-input"
                    (change)="chooseFile($event)"
                    accept="application/pdf"
                    [ngClass]="{
                      'is-invalid': submitted && formControls.billPdf.errors
                    }"
                  />
                  <label class="custom-file-label">{{
                    !!billForm.value.billPdf
                      ? 'Archivo cargado'
                      : 'Solo archivos pdf'
                    }}</label>
                  <small class="invalid-feedback">
                    La factura es obligatoria
                  </small>
                </div>
              </div>
            </div>
          </mat-tab>
          <mat-tab label="Clasificación">
            <div class="px-2 pb-0">
              <div class="d-flex flex-row"></div>

              <div class="mt-2">
                <div>
                  <div class="form-group d-flex flex-row">
                    <label
                      class="mr-1 align-self-center mb-0 grid-label"
                    >Orden de compra
                    </label>
                    <h5
                      class="mb-0 width-fit"
                      *ngIf="
                        !editingPurchaseOrder &&
                        !!isUpdate &&
                        !!bill.purchaseOrder
                      "
                    >
                      OC - {{ (bill.purchaseOrder | doc | async)?.purchaseID }}
                    </h5>

                    <h5 class="mb-0 width-fit" *ngIf="!!bill && !bill.purchaseOrder && !editingPurchaseOrder">
                      Sin OC
                    </h5>

                    <div *ngIf="editingPurchaseOrder" class="width-fit">
                      <button
                        class="btn btn-info btn-sm"
                        *ngIf="!!isUpdate && !hasPurchaseOrder"
                        (click)="switchPurchaseOrder()"
                      >
                        {{ hasPurchaseOrder ? 'Sin' : 'Con' }}
                        OC
                      </button>

                      <ng-select
                        [items]="allPurchaseOrders"
                        *ngIf="hasPurchaseOrder"
                        [searchable]="true"
                        (change)="handleChangePurchaseOrder()"
                        bindLabel="purchaseID"
                        placeholder="Seleccione una OC"
                        formControlName="purchaseOrder"
                      >
                        <ng-template ng-header-tmp>
                          <div
                            class="create-new"
                            (click)="switchPurchaseOrder()"
                          >
                            {{ hasPurchaseOrder ? 'Sin OC' : 'Con' }}
                          </div>
                        </ng-template>

                        <ng-template ng-option-tmp let-item="item">
                          OC -
                          {{
                          !!item.purchaseID
                            ? (item.purchaseID | slice: 0:-3)
                            : (item | doc | async)?.purchaseID
                          }}
                        </ng-template>

                        <ng-template
                          ng-label-tmp
                          let-item="item"
                          let-clear="clear"
                        >
                          <span
                            class="ng-value-label" *ngIf="!!item"
                          >OC -
                            {{
                            !!item.purchaseID
                              ? (item.purchaseID | slice: 0:-3)
                              : (item | doc | async)?.purchaseID
                            }}</span
                          >
                          <span
                            class="ng-value-icon right"
                            (click)="clear(item)"
                          >×</span
                          >
                        </ng-template>
                      </ng-select>
                    </div>
                    <div *ngIf="!isUpdate" class="width-fit">
                      <button
                        class="btn btn-info btn-sm"
                        *ngIf="
                          (!!editingModal || !isUpdate) && !hasPurchaseOrder
                        "
                        (click)="switchPurchaseOrder()"
                      >
                        {{ hasPurchaseOrder ? 'Sin' : 'Con' }}
                        OC
                      </button>
                      <ng-select
                        [items]="allPurchaseOrders"
                        class="width-fit"
                        *ngIf="hasPurchaseOrder"
                        [searchable]="true"
                        (change)="handleChangePurchaseOrder()"
                        bindLabel="purchaseID"
                        placeholder="Seleccione una OC"
                        formControlName="purchaseOrder"
                      >
                        <ng-template ng-header-tmp>
                          <div
                            class="create-new"
                            (click)="switchPurchaseOrder()"
                          >
                            {{ hasPurchaseOrder ? 'Sin OC' : 'Con OC' }}
                          </div>
                        </ng-template>

                        <ng-template ng-option-tmp let-item="item">
                          OC -
                          {{
                          !!item.purchaseID
                            ? (item.purchaseID | slice: 0:-3)
                            : (item | doc | async)?.purchaseID
                          }}
                        </ng-template>

                        <ng-template
                          ng-label-tmp
                          let-item="item"
                          let-clear="clear"
                        >
                          <span
                            class="ng-value-label" *ngIf="!!item"
                          >OC -
                            {{
                            !!item.purchaseID
                              ? (item.purchaseID | slice: 0:-3)
                              : (item | doc | async)?.purchaseID
                            }}</span
                          >
                          <span
                            class="ng-value-icon right"
                            (click)="clear(item)"
                          >×</span
                          >
                        </ng-template>
                      </ng-select>
                    </div>
                    <small
                      *ngIf="
                        hasPurchaseOrder &&
                        formControls.purchaseOrder.errors &&
                        submitted
                      "
                      class="text-muted danger"
                    >Campo obligatorio</small
                    >
                  </div>
                </div>

                <div class="form-group mb-1">
                  <div class="d-flex flex-row">
                    <label
                      class="mb-0 align-self-center mr-1 grid-label"
                    >Fecha prevista a pago</label
                    >
                    <h5
                      class="mb-0"
                      *ngIf="!editingExpectedPaymentDate && !!isUpdate"
                    >
                      {{ bill.expectedPaymentDate }}
                    </h5>
                    <div *ngIf="editingExpectedPaymentDate">
                      <input
                        class="form-control"
                        type="date"
                        formControlName="expectedPaymentDate"
                        (change)="setRealPaymentDate()"
                        [(ngModel)]="bill.expectedPaymentDate"
                        [ngClass]="{
                          'is-invalid':
                            submitted && formControls.expectedPaymentDate.errors
                        }"
                      />
                    </div>
                    <div *ngIf="!isUpdate">
                      <input
                        class="form-control"
                        type="date"
                        formControlName="expectedPaymentDate"
                        (change)="setRealPaymentDate()"
                        [ngClass]="{
                          'is-invalid':
                            submitted && formControls.expectedPaymentDate.errors
                        }"
                      />
                    </div>
                    <small
                      class="form-text text-muted danger invalid-feedback"
                      *ngIf="
                        submitted && formControls.expectedPaymentDate.errors
                      "
                    >
                      <div
                        *ngIf="
                          formControls.expectedPaymentDate.errors.required
                        "
                      >
                        La fecha prevista a pago es obligatorio
                      </div>
                    </small>
                  </div>
                </div>

                <div class="mb-1">
                  <div>
                    <div class="pt-1 pb-0">
                      <div class="form-group mb-1">
                        <div class="d-flex flex-row">
                          <label
                            class="mr-1 align-self-center grid-label"
                          >Proyecto o Centro de costos</label
                          >
                          <div
                            *ngIf="
                              !hasPurchaseOrder && (!!editingModal || !isUpdate)
                            "
                            class="w-40"
                          >
                            <ng-container>
                              <ng-select
                                [items]="projects"
                                *ngIf="!billForm.value.purchaseOrder"
                                [searchable]="true"
                                bindLabel="name"
                                appendTo="body"
                                placeholder="Selecciona un proyecto"
                                (change)="getFilterCategories()"
                                formControlName="project"
                                groupBy="type"
                              >
                                <ng-template ng-option-tmp let-item="item">
                                  {{ item.name }}
                                </ng-template>
                              </ng-select>
                            </ng-container>
                            <small
                              *ngIf="
                                !hasPurchaseOrder &&
                                formControls.project.errors &&
                                submitted
                              "
                              class="text-muted danger"
                            >Campo obligatorio</small
                            >
                          </div>

                          <h4
                            *ngIf="hasPurchaseOrder && !billForm.value.project"
                            class="text-black-50 mb-0"
                          >
                            Seleccione una OC
                          </h4>
                          <h4
                            class="mb-0"
                            *ngIf="hasPurchaseOrder && billForm.value.project"
                          >
                            {{
                            !billForm.value.project.name
                              ? (billForm.value.project | doc | async)?.name
                              : billForm.value.project.name
                            }}
                          </h4>
                          <h4
                            *ngIf="
                              !hasPurchaseOrder &&
                              !(!!editingModal || !isUpdate) &&
                              !billForm.value.project
                            "
                          >
                            Proyecto no asignado
                          </h4>
                        </div>
                      </div>
                    </div>

                    <div class="pt-1 pb-0">
                      <div class="form-group mb-1">
                        <div class="d-flex flex-row">
                          <label
                            class="mr-1 align-self-center grid-label"
                          >Categoría</label
                          >
                          <ng-container
                            *ngIf="
                              !hasPurchaseOrder && (!!editingModal || !isUpdate)
                            "
                          >
                            <input
                              type="text"
                              class="form-control w-40"
                              placeholder="Seleccione primero un proyecto o Centro de costos"
                              *ngIf="!billForm.value.project && !!editingModal"
                              [attr.disabled]="true"
                            />

                            <ng-select
                              [items]="paymentCategoriesFilter"
                              *ngIf="
                                billForm.value.project &&
                                !billForm.value.purchaseOrder
                              "
                              [searchable]="true"
                              class="w-40"
                              bindLabel="name"
                              appendTo="body"
                              placeholder="Selecciona una categoría"
                              formControlName="projectCategory"
                              groupBy="type"
                            >
                              <ng-template ng-option-tmp let-item="item">
                                {{ item.name }}
                              </ng-template>
                              <ng-template ng-label-tmp let-item="item">
                                {{ item.name }}
                              </ng-template>
                            </ng-select>
                            <small
                              *ngIf="
                                !hasPurchaseOrder &&
                                formControls.projectCategory.errors &&
                                submitted
                              "
                              class="text-muted danger"
                            >Campo obligatorio</small
                            >
                          </ng-container>

                          <h4
                            *ngIf="
                              hasPurchaseOrder &&
                              !billForm.value.projectCategory
                            "
                            class="text-black-50 mb-0"
                          >
                            Seleccione una OC
                          </h4>
                          <h4
                            class="mb-0"
                            *ngIf="hasPurchaseOrder && billForm.value.projectCategory"
                          >
                            {{
                            !billForm.value.projectCategory?.name
                              ? (billForm.value.projectCategory | doc | async)
                                ?.name
                              : billForm.value.projectCategory.name
                            }}
                          </h4>
                          <h4
                            *ngIf="
                              !hasPurchaseOrder &&
                              !(!!editingModal || !isUpdate) &&
                              !billForm.value.projectCategory
                            "
                          >
                            Categoría no asignada
                          </h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </mat-tab>
        </mat-tab-group>

        <div class="modal-footer border-0 button-position">
          <button
            class="btn btn-success add-btn"
            *ngIf="!isUpdate"
            (click)="confirmOC()"
          >
            Guardar
          </button>
          <button
            type="button"
            class="btn btn-success add-btn"
            *ngIf="isUpdate && !editingModal"
            (click)="updateLabels()"
          >
            Editar
          </button>
          <div
            class="btn btn-info cancel-btn cursor-pointer"
            *ngIf="!!editingModal"
            (click)="applyUpdate()"
          >
            Aplicar
          </div>
          <div
            class="btn btn-danger cancel-btn cursor-pointer"
            *ngIf="!!editingModal"
            (click)="cancelEditing()"
          >
            Cancelar
          </div>
          <div
            class="btn btn-danger cancel-btn cursor-pointer"
            *ngIf="!editingModal"
            (click)="modal.hide()"
          >
            Cerrar
          </div>
        </div>
      </form>
    </div>

    <div class="col-5" *ngIf="!!isUpdate && !!billForm.value.billPdf">
      <mat-tab-group>
        <mat-tab label="PDF de la Factura">
          <div class="col-lg-12 col-md-12 col-sm-12 center-layout center">
            <div class="card shadow-none">
              <div class="card-body p-0">
                <div class="write-post">
                  <div class="col-sm-12 px-0">
                    <div [innerHTML]="urlBill"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </mat-tab>
        <mat-tab
          label="Comprobante de pago"
          *ngIf="!!billForm.value.proofOfPayment"
        >
          <div class="col-12 center-layout center">
            <div class="card shadow-none">
              <div class="card-body p-0">
                <div class="write-post">
                  <div class="col-sm-12 px-0">
                    <div
                      [innerHTML]="url"
                      *ngIf="!!billForm.value.proofOfPayment"
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </mat-tab>
      </mat-tab-group>
    </div>
  </div>
</block-ui>
