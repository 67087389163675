import { AfterViewInit, Component, ElementRef, OnDestroy, ViewChild } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { UserService } from '../../../shared/services/user.service';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Location } from '@angular/common';

@Component({
  selector: 'app-people-management',
  templateUrl: './people-management.component.html',
  styleUrls: ['./people-management.component.css']
})
export class PeopleManagementComponent implements AfterViewInit, OnDestroy {
  @ViewChild('iframe', { static: false }) iframe!: ElementRef;
  firstLoad = true;
  peopleManagementUrl: string;
  routeSubscription: Subscription;
  baseUrl: string = '/admin/people-management';
  prevUrlSection: string = '';

  constructor(
    private _user: UserService,
    private router: Router,
    private location: Location
  ) {
    this.getMessageData = this.getMessageData.bind(this);
  }

  getUrlParams(url: string) {
    if (url.includes('/applicant/')) {
      const applicantId = url.split('/').pop();
      return btoa(JSON.stringify({
        path: `people-management/applicant/${applicantId}`
      }));
    } else {
      const subRoute = url.split('/').slice(2);
      let paramType = subRoute[2]?.includes('tab-') ? 'tab' : 'search';
      let paramData = subRoute[2]?.includes('tab-') ? subRoute[subRoute.length - 1].split('tab-')[1] : subRoute[subRoute.length - 1];

      return btoa(JSON.stringify({
        path: subRoute.slice(0, 2).join('/'),
        params: subRoute.length > 2 ? { [paramType]: paramData } : {}
      }));
    }
  }

  setUrl(url: string) {
    this._user.getValidationToken().then(token => {
      token = btoa(token);

      let dataParam = url.includes('data=') ? url.split('data=')[1] : '';
      let currentData = this.getUrlParams(url);

      if (dataParam) {
        dataParam = `&data=${dataParam}`;
      } else {
        if (currentData) {
          dataParam = `&data=${currentData}`;
        }
      }

      this.peopleManagementUrl = `${environment.embeddedUrl}/#/people-management?uid=${this._user.user.key}&token=${token}${dataParam}`;
      this.firstLoad = false;
    });
  }

  handleInternalUrl(url: string) {
    const iframe: HTMLIFrameElement = this.iframe.nativeElement;
    const data = this.getUrlParams(url);

    iframe.contentWindow.postMessage(`?data=${data}`, '*');
  }

  getMessageData(event) {
    if (event.origin !== environment.embeddedUrl) return;
    if (event.data) {
      this.location.replaceState(this.baseUrl + event.data);
    } else {
      if (this.prevUrlSection) this.location.replaceState(this.baseUrl + this.prevUrlSection);
    }
  }

  ngAfterViewInit(): void {
    if (this.firstLoad) this.setUrl(window.location.pathname);
    this.prevUrlSection = window.location.href.split(this.baseUrl)[1];

    this.routeSubscription = this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.handleInternalUrl(event.url);
        this.prevUrlSection = event.url.split(this.baseUrl)[1];
      }
    });

    window.addEventListener('message', this.getMessageData, false);
  }

  ngOnDestroy() {
    this.routeSubscription.unsubscribe();
    window.removeEventListener('message', this.getMessageData, false);
  }
}
