<!-- main app container -->
<ngx-loading-bar [includeSpinner]='false' height='3px' color="#FF4961"></ngx-loading-bar>
<h1
  *ngIf="!window.location.hostname.includes('plataforma.colsan.cl')"
  class="position-sticky"
  style="color: blue; font-size: 20px; text-align: center;"
>
  <span
    class="badge badge-success text-center w-100 "
    *ngIf="!environment.production && environment.firebase.projectId == 'colsan-prod'"
  >
    PRODUCTION
  </span>
</h1>
<router-outlet *ngIf="showContent"></router-outlet>
<app-floating-menu></app-floating-menu>
