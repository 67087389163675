import { Injectable } from '@angular/core';
import { FirebaseDataService } from '../template-services/firebase-data.service';
import { AngularFirestore, DocumentReference } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { PurchaseOrder } from '../../admin/pages/purchase-orders/interfaces/purchase-order';
import { take } from 'rxjs/operators';
import { Record } from '../../admin/interfaces/comment';
import { AngularFireStorage } from '@angular/fire/storage';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { PurchaseStatus } from '../../admin/enums/purchase-status.enum';
import html2canvas from 'html2canvas';

@Injectable({
  providedIn: 'root'
})
export class PurchaseOrderService {
  constructor(
    private db: FirebaseDataService,
    private afs: AngularFirestore,
    private storage: AngularFireStorage,
    private http: HttpClient
  ) {
  }

  getAll(): Observable<PurchaseOrder[]> {
    return this.db.colWithIds$<PurchaseOrder>('purchaseOrders', (ref) =>
      ref.orderBy('purchaseID', 'desc').where('trash', '==', false)
    );
  }

  getAllAuthorized() {
    return this.db.colWithIds$<PurchaseOrder>('purchaseOrders', (ref) =>
      ref.where('trash', '==', false).where('status', '==', PurchaseStatus.AUTHORIZED).orderBy('purchaseID', 'desc')
    );
  }

  get(purchaseKey: string): Observable<PurchaseOrder> {
    return this.db.docWithId$(`purchaseOrders/${purchaseKey}`);
  }

  getAllNotInvoiced(): Observable<PurchaseOrder[]> {
    return this.db.colWithIds$<PurchaseOrder>('purchaseOrders', (ref) =>
      ref
        .orderBy('purchaseID', 'desc')
        .where('trash', '==', false)
        .where('invoiced', '==', false)
    );
  }

  getAllInvoiced(): Observable<PurchaseOrder[]> {
    return this.db.colWithIds$<PurchaseOrder>('purchaseOrders', (ref) =>
      ref
        .orderBy('purchaseID', 'desc')
        .where('trash', '==', false)
        .where('invoiced', '==', true)
    );
  }

  add(purchaseOrder: PurchaseOrder): Promise<DocumentReference> {
    return this.afs.collection(`purchaseOrders`).add(purchaseOrder);
  }

  set(purchaseKey: string, purchaseOrder: PurchaseOrder): void {
    this.afs
      .doc(`purchaseOrders/${purchaseKey}`)
      .set(purchaseOrder, { merge: true });
  }

  delete(purchaseOrderKey: string): Promise<void> {
    return this.afs
      .doc<PurchaseOrder>(`purchaseOrders/${purchaseOrderKey}`)
      .update({ trash: true });
  }

  update(purchaseOrderKey: string, purchaseOrder: Partial<PurchaseOrder>): Promise<void> {
   return this.db.update(`purchaseOrders/${purchaseOrderKey}`, purchaseOrder);
  }

  createId(): string {
    return this.afs.createId();
  }

  async purchaseId(): Promise<number> {
    const settings = await this.db
      .doc('settings/setting')
      .valueChanges()
      .pipe(take(1))
      .toPromise();

    // @ts-ignore
    return settings.purchaseID;
  }

  updatePurchaseID(purchaseID: number) {
    this.afs.doc('settings/setting').update({ purchaseID });
  }

  async uploadInvoice(
    file,
    purchaseOrderKey: string,
    nameFile: string,
    extension: string
  ) {
    const uploadRef = this.getStorageRefFile(
      purchaseOrderKey,
      nameFile,
      extension
    );
    await uploadRef.put(file);
    const url = await uploadRef.getDownloadURL().pipe(take(1)).toPromise();
    this.uploadFileStorage(file, purchaseOrderKey, nameFile, extension);

    return url;
  }

  getStorageRefFile(id: string, nameFile: string, extension) {
    return this.storage.ref(`purchase-order/${id}/${nameFile}.${extension}`);
  }

  uploadFileStorage(data, id, nameFile: string, extension) {
    return this.storage.upload(`medics/${id}/${nameFile}.${extension}`, data);
  }

  getComments(purchaseKey: any): Observable<any[]> {
    return this.afs
      .collection(`purchaseOrders/${purchaseKey}/comments`)
      .valueChanges();
  }

  async addComment(purchaseKey: any, comment: any): Promise<any> {
    return await this.afs
      .collection<Record>(`purchaseOrders/${purchaseKey}/comments`)
      .add(comment);
  }

  sendPdfToProvider(
    pdfUrl: string,
    recipients: string[],
    comment: string,
    purchaseID: number
  ) {
    this.http
      .post(`${environment.apiBaseURL}/sendPdfProvider`, {
        pdfUrl,
        recipients,
        comment,
        purchaseID
      })
      .pipe(take(1))
      .toPromise();
  }

  getReference(key: string) {
    return this.db.getReference(`purchaseOrders/${key}`);
  }

  getBudget(path: string) {
    return this.db.doc$(path);
  }

  async updateDescription(purchaseOrderKey: string, description: string) {
    return await this.afs
      .doc<PurchaseOrder>(`purchaseOrders/${purchaseOrderKey}`)
      .update({ description });
  }

  updateFinancialThreshold(threshold: number) {
    return this.afs.doc('settings/setting').update({ financeProfileThreshold: threshold });
  }

  async getFinanceThreshold(): Promise<number> {
    return (await this.db.doc$('settings/setting').pipe(take(1)).toPromise() as any).financeProfileThreshold || 0;
  }

  updateComercialConditions(comercialConditions: string) {
    return this.afs.doc('settings/setting').update({ comercialConditions });
  }

  async getComercialConditions(): Promise<any> {
    return (await this.db.doc$('settings/setting').pipe(take(1)).toPromise() as any).comercialConditions || '';
  }

  async uploadPOImage(purchaseOrderKey: string, fileName: string): Promise<string> {
    let url = '';
    let node = document.getElementById('purchase-order-details-node');

    if (!node) {
      console.error('No se encontró el nodo purchase-order-details-node en el DOM.');
      return url;
    }

    let nodeCopy = node.cloneNode(true) as HTMLElement;
    const status = nodeCopy.querySelector('#purchase-order-status-node') as HTMLElement;
    const buttons = nodeCopy.querySelector('#purchase-buttons-node') as HTMLElement;

    if (status) status.parentNode.removeChild(status);
    if (buttons) buttons.parentNode.removeChild(buttons);

    nodeCopy.style.position = 'absolute';
    nodeCopy.style.height = node.style.height;
    nodeCopy.style.width = node.style.width;
    nodeCopy.style.top = '-9999px';
    nodeCopy.style.left = '-9999px';

    document.body.appendChild(nodeCopy as Node);

    try {
      await this.waitForImagesToLoad(nodeCopy);

      let canvas = await html2canvas(nodeCopy, { scrollY: -window.scrollY, useCORS: true });
      let blob = await new Promise<Blob>(resolve => canvas.toBlob(resolve, 'image/png', 0.9));
      const newFileName = fileName || `purchaseOrderImage.jpeg`;
      if (blob) {
        let file = new File([blob], newFileName, { type: 'image/png' });
        url = await this.db.uploadFile(
          file,
          `${purchaseOrderKey}`,
          newFileName,
          'jpeg'
        );

        await this.update(purchaseOrderKey, { purchaseOrderImageUrl: url });
      }
    } catch (error) {
      console.error('Error generando el canvas', error);
    } finally {
      if (nodeCopy && nodeCopy.parentNode) {
        nodeCopy.parentNode.removeChild(nodeCopy);
      }
    }
    return url;
  }

  waitForImagesToLoad(node: HTMLElement): Promise<any> {
    const images = node.querySelectorAll('img');
    const promises = Array.from(images).map(image => {
      return new Promise<void>((resolve, reject) => {
        if (image.complete) {
          resolve();
        } else {
          image.onload = () => resolve();
          image.onerror = (error) => reject(error);
        }
      });
    });
    return Promise.all(promises);
  }
}
