import { Routes } from '@angular/router';
import { PrivateLayoutComponent } from '../layout/private-layout/private-layout.component';
import { CategoriesComponent } from './pages/categories/categories.component';
import { UsersListComponent } from './pages/users-list/users-list.component';
import { WarehouseDetailsComponent } from './pages/warehouses/components/warehouse-details/warehouse-details.component';
import { UserDetailsComponent } from './pages/user-details/user-details.component';
import { ProjectDetailsComponent } from './pages/project-details/project-details.component';
import { PositionsComponent } from './pages/positions/positions.component';
import { QuizComponent } from './pages/quiz/quiz.component';
import { StatusComponent } from './pages/status/status.component';
import { QuizDetailsComponent } from './pages/quiz-details/quiz-details.component';
import { AddQuizComponent } from './modals/add-quiz/add-quiz.component';
import { AfpComponent } from './pages/afp/afp.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { ProvidersComponent } from './pages/providers/providers.component';
import { PurchaseOrdersComponent } from './pages/purchase-orders/purchase-orders.component';
import { IdSettingsComponent } from './pages/id-settings/id-settings.component';
import { BillsComponent } from './pages/bills/bills.component';
import { ProvidersArticlesComponent } from './pages/providers-articles/providers-articles.component';
import { UnitsComponent } from './pages/units/units.component';
import { ProjectCategoriesComponent } from './pages/project-categories/project-categories.component';
import { ProofPaymentsComponent } from './pages/proof-payments/proof-payments.component';
import { CostCenterComponent } from './pages/cost-center/cost-center.component';
import { CostCenterDetailsComponent } from './pages/cost-center-details/cost-center-details.component';
import { WorkerSalariesComponent } from './pages/worker-salaries/worker-salaries.component';
import { TotalExpensesComponent } from './pages/total-expenses/total-expenses.component';
import { ReportsComponent } from './pages/reports/reports.component';
import { ChangeLogComponent } from './components/change-log/change-log.component';
import { KanbanComponent } from './pages/kanban/kanban.component';
import { BoardsComponent } from './pages/boards/boards.component';
import { TasksListComponent } from './pages/tasks-list/tasks-list.component';
import { NotificationsComponent } from './pages/notifications/notifications.component';
import { ClientsComponent } from './pages/clients/clients.component';
import { WarehouseSummaryComponent } from './pages/warehouses/components/warehouse-details/warehouse-summary/warehouse-summary.component';
import { ArticlesComponent } from './pages/warehouses/components/warehouse-details/articles/articles.component';
import { TicketsComponent } from './pages/warehouses/components/warehouse-details/tickets/tickets.component';
import { ArticlesReturnedComponent } from './pages/warehouses/components/warehouse-details/articles-returned/articles-returned.component';
import { LogsComponent } from './pages/warehouses/components/warehouse-details/logs/logs.component';
import {
  TicketAuthorizationComponent
} from './pages/warehouses/components/warehouse-details/ticket-authorization/ticket-authorization.component';
import { SalesBoardsComponent } from './pages/sales-boards/sales-boards.component';
import { SalesKanbanComponent } from './pages/sales-kanban/sales-kanban.component';
import { SalesReportComponent } from './pages/sales-report/sales-report.component';
import {
  TicketsArticleEntryComponent
} from './pages/warehouses/components/warehouse-details/tickets-article-entry/tickets-article-entry.component';
import { PurchaseOrderArticlesComponent } from './pages/purchase-order-articles/purchase-order-articles.component';
import { MinimumStockComponent } from './pages/warehouses/components/warehouse-details/minimum-stock/minimum-stock.component';
import {
  BreakdownAmountCategoriesComponent
} from './pages/travel-advance/pages/breakdown-amount-categories/breakdown-amount-categories.component';
import { AccountabilitiesComponent } from './pages/travel-advance/pages/accountabilities/accountabilities.component';
import { ReceiptComponent } from './pages/travel-advance/pages/receipt/receipt.component';
import { AccountabilityReceiptsComponent } from './pages/travel-advance/pages/accountability-receipts/accountability-receipts.component';
import { ProjectInvoiceReportsComponent } from './pages/project-invoice-reports/project-invoice-reports.component';
import { WarehousesModule } from './pages/warehouses/warehouses.module';
import { AdminAccountabilitiesComponent } from './pages/travel-advance/pages/admin-accountabilities/admin-accountabilities.component';
import { MyTasksKanbanComponent } from './pages/my-tasks-kanban/my-tasks-kanban.component';
import { SalaryRangeComponent } from './pages/dashboard/components/salary-range/salary-range.component';
import { PurchaseOrderWrapperComponent } from './pages/purchase-orders/components/purchase-order-wrapper/purchase-order-wrapper.component';
import { EmptyComponent } from './pages/empty/empty.component';

export const AdminRoutes: Routes = [
  {
    path: '',
    component: PrivateLayoutComponent,
    children: [
      {
        path: 'blog',
        component: EmptyComponent
      },
      {
        path: 'notifications',
        component: NotificationsComponent
      },
      {
        path: 'categories',
        component: CategoriesComponent
      },
      {
        path: 'users',
        component: UsersListComponent
      },
      {
        path: 'warehouse',
        loadChildren: () => WarehousesModule
      },
      {
        path: 'warehouse/:tab',
        loadChildren: () => WarehousesModule
      },
      {
        path: 'warehouseDetails/:warehouseKey',
        component: WarehouseDetailsComponent,
        children: [
          {
            path: 'summary',
            component: WarehouseSummaryComponent
          },
          {
            path: 'articles',
            component: ArticlesComponent
          },
          {
            path: 'tickets',
            component: TicketsComponent
          },
          {
            path: 'tickets/:ticketKey',
            component: TicketsComponent
          },
          {
            path: 'tickets-article-entry/:ticketKey',
            component: TicketsArticleEntryComponent
          },
          {
            path: 'articles-returned',
            component: ArticlesReturnedComponent
          },
          {
            path: 'logs',
            component: LogsComponent
          },
          {
            path: 'requests',
            component: TicketAuthorizationComponent
          },
          {
            path: 'minimum-stock',
            component: MinimumStockComponent
          },
          {
            path: 'tickets-article-entry',
            component: TicketsArticleEntryComponent
          },
          {
            path: '**',
            redirectTo: 'summary'
          }
        ]
      },
      {
        path: 'warehouseDetails/:warehouseKey/:ticketKey',
        component: WarehouseDetailsComponent
      },
      {
        path: 'user-details/:userKey',
        component: UserDetailsComponent
      },
      {
        path: 'projects',
        component: EmptyComponent,
        children: [
          {
            path: ':id',
            component: EmptyComponent
          }
        ]
      },
      {
        path: 'projectDetails/:projectKey',
        component: ProjectDetailsComponent
      },
      {
        path: 'positions',
        component: PositionsComponent
      },
      {
        path: 'quiz',
        component: QuizComponent
      },
      {
        path: 'status',
        component: StatusComponent
      },
      {
        path: 'afp',
        component: AfpComponent
      },
      {
        path: 'quizDetails/:quizKey',
        component: QuizDetailsComponent
      },
      {
        path: 'addQuiz',
        component: AddQuizComponent
      },
      {
        path: 'dashboard',
        component: DashboardComponent
      },
      {
        path: 'home/:userKey',
        component: UserDetailsComponent
      },
      {
        path: 'providers',
        component: ProvidersComponent
      },
      {
        path: 'purchase-orders',
        component: PurchaseOrdersComponent
      },
      {
        path: 'purchase-orders/new',
        component: PurchaseOrderWrapperComponent
      },
      {
        path: 'purchase-orders/modal/:purchaseOrderKey',
        component: PurchaseOrdersComponent
      },
      {
        path: 'purchase-orders/details/:purchaseOrderKey',
        component: PurchaseOrderWrapperComponent
      },
      {
        path: 'articles-to-come',
        component: PurchaseOrderArticlesComponent
      },
      {
        path: 'id-settings',
        component: IdSettingsComponent
      },
      {
        path: 'bills',
        component: BillsComponent
      },
      {
        path: 'providerArticles',
        component: ProvidersArticlesComponent
      },
      {
        path: 'units',
        component: UnitsComponent
      },
      {
        path: 'projectCategories',
        component: ProjectCategoriesComponent
      },
      {
        path: 'proofPayments',
        component: ProofPaymentsComponent
      },
      {
        path: 'costCenters',
        component: CostCenterComponent
      },
      {
        path: 'costCenterDetails/:costCenterKey',
        component: CostCenterDetailsComponent
      },
      {
        path: 'workerSalaries',
        component: WorkerSalariesComponent
      },
      {
        path: 'totalExpenses',
        component: TotalExpensesComponent
      },
      {
        path: 'reports',
        component: ReportsComponent
      },
      {
        path: 'changeLog',
        component: ChangeLogComponent
      },
      {
        path: 'kanban/:boardKey',
        component: KanbanComponent
      },
      {
        path: 'boards',
        component: BoardsComponent
      },
      {
        path: 'boards-v2',
        component: EmptyComponent
      },
      {
        path: 'sales-boards',
        component: SalesBoardsComponent
      },
      {
        path: 'breakdown-amount-categories',
        component: BreakdownAmountCategoriesComponent
      },
      {
        path: 'accountabilities',
        component: AccountabilitiesComponent
      },
      {
        path: 'new-receipt',
        component: ReceiptComponent
      },
      {
        path: 'kanban/:boardKey/tasks-list',
        component: TasksListComponent
      },
      {
        path: 'clients',
        component: ClientsComponent
      },
      {
        path: 'sales-boards-sales-kanban/:salesBoardKey',
        component: SalesKanbanComponent
      },
      {
        path: 'sales/report',
        component: SalesReportComponent
      },
      {
        path: 'accountabilities-details/:accountabilityKey',
        component: AccountabilityReceiptsComponent
      },
      {
        path: 'project-invoice-reports',
        component: ProjectInvoiceReportsComponent
      },
      {
        path: 'manage-accountabilities',
        component: AdminAccountabilitiesComponent
      },
      {
        path: 'vehicles',
        component: EmptyComponent
      },
      {
        path: 'my-tasks-kanban',
        component: MyTasksKanbanComponent
      },
      {
        path: 'fund-to-render',
        component: EmptyComponent
      },
      {
        path: 'salary-range',
        component: SalaryRangeComponent
      },
      {
        path: 'people-management',
        component: EmptyComponent,
        children: [
          {
            path: '',
            redirectTo: 'applicants',
            pathMatch: 'full'
          },
          {
            path: 'applicants'
          },
          {
            path: 'applicants/:status'
          },
          {
            path: 'applicant/:id'
          },
          {
            path: 'recruitment'
          },
          {
            path: 'recruitment/:status'
          },
          {
            path: 'recruitment-board'
          },
          {
            path: 'hired'
          },
          {
            path: 'inductions-expired'
          },
          {
            path: 'exams-expired'
          },
          {
            path: 'inactive'
          },
          {
            path: 'dashboard'
          },
          {
            path: 'email-templates'
          },
          {
            path: 'email-templates/:type'
          },
          {
            path: 'schedules'
          },
          {
            path: 'filters'
          },
          {
            path: 'calendar'
          },
          {
            path: 'event-types'
          },
          {
            path: 'exam-types'
          },
          {
            path: 'skills'
          }
        ]
      },
      {
        path: 'sales-v2',
        component: EmptyComponent,
        children: [
          {
            path: ''
          },
          {
            path: 'inline-modal/sale/:id'
          },
          {
            path: 'list'
          },
          {
            path: 'calendar'
          },
          {
            path: 'contacts'
          },
          {
            path: 'clients'
          },
          {
            path: 'logs'
          },
          {
            path: 'config'
          }
        ]
      },
      {
        path: '**',
        redirectTo: 'blog'
      }
    ]
  }
];
