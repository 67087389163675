import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { DataTableConfig } from '../../../shared/interfaces/data-table-config';
import { BillService } from '../../../shared/services/bill.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BillModalComponent } from '../../modals/bill-modal/bill-modal.component';
import { first, take } from 'rxjs/operators';
import { NgxSpinnerService } from 'ngx-spinner';
import { AddToExcelComponent } from '../../components/add-to-excel/add-to-excel.component';
import { Bill } from '../../interfaces/bill';
import { UserService } from '../../../shared/services/user.service';
import { UserType } from '../../enums/user-type.enum';
import { AlertService } from '../../../shared/template-services/alert.service';
import { formatDate } from '@angular/common';
import moment from 'moment';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { BillModalV2Component } from '../../modals/bill-modal-v2/bill-modal-v2.component';
import _ from 'lodash';
import { ExportToExcelService } from '../../../shared/services/export-to-excel.service';
import { ExcelHeader } from '../../interfaces/excel-header';
import { SelectColumnsToPrintComponent } from '../../modals/select-columns-to-print/select-columns-to-print.component';
import { PopulateRowServiceService } from '../../../shared/services/populate-row-service.service';

@Component({
  selector: 'app-bills',
  templateUrl: './bills.component.html',
  styleUrls: ['./bills.component.css']
})
export class BillsComponent implements OnInit, OnDestroy {
  billsFiltered: Bill[] = [];
  config: DataTableConfig = {
    hasSearch: true,
    notFoundText: 'No se encontraron facturas',
    title: '',
    propTotal: 'total',
    pagination: true,
    paginationLimit: 25,
    exportCallback: this.printToExcel.bind(this)
  };
  startDate: any = null;
  finalDate: any = null;
  status: any;
  permission: UserType;
  permissionEnum = UserType;
  isClassified: boolean;
  isExpirated: boolean = null;
  providers: any[] = [];
  providerSelected = null;
  statusNotPayed: boolean;
  statusPayed: boolean;
  isnClassified: boolean;
  isnExpirated: boolean;
  today;
  todayMs;
  isOpenModal: boolean;
  billSubscription: Subscription = new Subscription();
  isUploading: boolean = false;
  bills: Bill[] = [];

  constructor(private _bill: BillService,
    private modal: BsModalService,
    private SpinnerService: NgxSpinnerService,
    private _user: UserService,
    private http: HttpClient,
    private populateRowService: PopulateRowServiceService,
    private excelExport: ExportToExcelService) {
  }

  ngOnInit() {
    this.billSubscription = this._bill.getAll().subscribe(async (bills) => {
      this.bills = bills.map((bill) => {
        let isPaid = !!bill.isPaid;
        return {
          ...bill,
          isPaid,
          isExpirated:
            moment(bill.expectedPaymentDate).toDate().getTime() <
            moment().startOf('day').toDate().getTime() && !isPaid
        };
      });

      this.providers = _.uniqBy(this.bills, 'provider');

      this.filterBills();
    });

    this.today = this.formatDate(new Date().getTime());
    this.todayMs = new Date().getTime();

    const currentDate: Date = new Date();
    const date = new Date(
      `${new Date().getMonth() + 1}/01/${new Date().getFullYear()}`
    );
    this.startDate = this.formatDate(
      moment(date).subtract(3, 'month').format('YYYY-MM-DD')
    );
    this.finalDate = this.formatDate(currentDate);
    this.permission = this._user.user.permissions.find(
      (permission) => permission.section == 'TESORERÍA'
    ).permission;
  }

  ngOnDestroy() {
    this.billSubscription.unsubscribe();
  }

  private formatDate(date) {
    const deliveryDateDate = new Date(date);
    const format = 'yyyy/MM/dd';
    const locale = 'en-US';
    const zone = 'UTC';
    const formattedDate = formatDate(deliveryDateDate, format, locale, zone);
    return formattedDate.replace(/\//g, '-');
  }

  async gotoOpenEditBill(bill) {
    this.isOpenModal = true;
    const modalRef = this.modal.show(BillModalV2Component, {
      initialState: {
        bill: { ...bill },
        bills: this.bills,
        isUpdate: true,
        permission: this.permission
      },
      class: 'modal-xl',
      backdrop: 'static',
      id: 8177
    });

    await modalRef.onHidden.pipe(first()).toPromise();
    this.isOpenModal = false;
  }

  openAddBillModal() {
    this.modal.show(BillModalComponent, {
      initialState: {
        isUpdate: false,
        permission: this.permission
      },
      class: 'modal-lg',
      backdrop: 'static',
      id: 8177
    });
  }

  addToExcel() {
    const modalRef = this.modal.show(AddToExcelComponent, {
      class: 'modal-md'
    });

    modalRef.onHide.pipe(take(1)).subscribe(async () => {
      if (modalRef.content.send) {
        this.isUploading = true;
        this.SpinnerService.show();

        const formData = new FormData();
        formData.append('file', modalRef.content.file);

        try {
          const resp: any = await this.http.post(`${environment.apiBaseURLV1}/bills/massive`, formData).pipe(first()).toPromise();
          this.SpinnerService.hide();

          resp.ok
            ? AlertService.toastSuccess('Se han agregado las facturas correctamente')
            : AlertService.toastError('Ha ocurrido un error al agregar las facturas');
        } catch (error) {
          this.SpinnerService.hide();
          AlertService.toastError('Ha ocurrido un error al agregar las facturas');
        }

        this.isUploading = false;
      }
    });
  }

  async resetBills() {
    return this.billsFiltered = await this.bills;
  }

  async filterBills() {
    if (this.status == 99) {
      return this.resetBills();
    }

    if (
      !this.startDate &&
      !this.finalDate &&
      this.status == undefined &&
      this.isClassified == undefined
    ) {
      return;
    }

    let startDate;
    let startDateMilliseconds;
    let finalDate;
    let finalDateMilliseconds;

    if (this.startDate) {
      startDate = this.startDate.replaceAll('-', '/');
      startDateMilliseconds = new Date(startDate).getTime();
    } else {
      startDateMilliseconds = 0;
    }

    if (this.finalDate) {
      finalDate = this.finalDate.replaceAll('-', '/');
      finalDateMilliseconds = new Date(finalDate).getTime();
    } else {
      finalDateMilliseconds = Infinity;
    }

    const billsFiltered = this.bills
      .filter(
        (bill) =>
          new Date(!this.isExpirated ? bill.startDate : bill.expectedPaymentDate).getTime() >= startDateMilliseconds &&
          new Date(!this.isExpirated ? bill.startDate : bill.expectedPaymentDate).getTime() <= finalDateMilliseconds
      )
      .filter((bill) => {
        if (this.statusNotPayed && this.statusPayed) {
          return true;
        } else if (this.statusPayed) {
          return bill.isPaid;
        } else if (this.statusNotPayed) {
          return !bill.isPaid;
        }

        return true;
      })
      .filter((bill) => {
        if (this.isClassified && this.isnClassified) {
          return true;
        } else if (this.isClassified) {
          return bill.isUsed;
        } else if (this.isnClassified) {
          return !bill.isUsed;
        }

        return true;
      })
      .filter((bill) => {
        if (this.isExpirated && this.isnExpirated) {
          return true;
        } else if (this.isExpirated) {
          return bill.isExpirated;
        } else if (this.isnExpirated) {
          return !bill.isExpirated;
        }

        return true;
      })
      .filter((bill) => {
        if (!this.providerSelected) return true;

        return this.providerSelected.provider.toUpperCase().trim() == bill.provider.toUpperCase().trim();
      });

    this.billsFiltered = billsFiltered;
  }

  public getRut(provider) {
    if (!provider) return '-';

    return provider.substr(0, provider.indexOf(' '));
  }

  public getProvider(provider) {
    if (!provider) return '-';

    return provider.substr(provider.indexOf(' ') + 1);
  }

  async deleteBill(billKey: string) {
    if (await AlertService.confirm('¿Estás seguro que deseas eliminar esta factura?')) {
      this._bill.delete(billKey);
    }
  }

  printToExcel() {
    const excelData = [
      { name: 'Nº Factura', att: 'documentNumber' },
      { name: 'Rut', att: 'rut' },
      { name: 'Total', att: 'total', type: 'currency' },
      { name: 'Centro de costo', att: 'costCenter' },
      { name: 'Proveedor', att: 'provider' },
      { name: 'Fecha de pago', att: 'realPaymentDate', type: 'date' },
      { name: 'Fecha de emisión', att: 'createdAt', type: 'date' }
    ] as ExcelHeader[];

    const modalRef = this.modal.show(SelectColumnsToPrintComponent, {
      initialState: {
        headers: excelData
      }
    });

    modalRef.onHide.pipe(take(1)).subscribe(async () => {
      if (modalRef.content.willPrint) {
        const selectedHeaders = modalRef.content.dataToPrint;
        const populatedData = await Promise.all(
          this.billsFiltered.map(async (bill) => ({
            ...bill,
            provider: this.getProvider(bill.provider),
            rut: this.getRut(bill.provider),
            costCenter: await this.populateRowService.billCostCenter(bill)
          }))
        );

        this.excelExport.exportAsExcelFile(selectedHeaders, populatedData, 'facturas');
      }
    });
  }
}
