import {Component, OnInit} from '@angular/core';
import {Observable, of, Subscription} from 'rxjs';
import {map} from 'rxjs/operators';
import {ProviderService} from '../../../shared/services/provider.service';
import {DataTableConfig} from '../../../shared/interfaces/data-table-config';
import {BsModalService} from 'ngx-bootstrap/modal';
import {ProviderModalComponent} from '../../modals/provider-modal/provider-modal.component';
import {Provider} from '../../interfaces/provider';
import {ObjectService} from 'src/app/shared/template-services/object.service';
import {AlertService} from '../../../shared/template-services/alert.service';

@Component({
  selector: 'app-providers',
  templateUrl: './providers.component.html',
  styleUrls: ['./providers.component.css']
})
export class ProvidersComponent implements OnInit {
  providers$: Observable<Provider[]>;
  providersFiltered$: Observable<Provider[]>;
  cities: { value: string, label: string }[] = [];
  selectedCity = '';
  providersSubscription: Subscription = new Subscription();
  config: DataTableConfig = {
    hasSearch: true,
    notFoundText: 'No se encontraron proveedores',
    title: '',
    exportCallback: this.decoratedProvidersToExport.bind(this),
    excelFileName: 'Proveedores',
    pagination: true,
    paginationLimit: 25,
    removeInfiniteScroll: true
  };

  constructor(private _provider: ProviderService,
              private modal: BsModalService) {
  }

  ngOnInit(): void {
    this.getProviders();
  }

  getProviders() {
    this.providersSubscription = this._provider.getAll().subscribe(providers => {
      this.providers$ = of(providers);
      this.providersFiltered$ = this.providers$;

      this.cities = Array.from(new Set(providers.map(provider => {
        return provider.city?.trim().toLowerCase();
      }))).map(city => ({
        value: city,
        label: city
      }));
    });
  }

  filterProviders() {
    this.providersFiltered$ = this.providers$.pipe(
      map(providers => {
        return providers.filter(provider => {
          return !this.selectedCity || provider.city?.trim().toLowerCase() === this.selectedCity.toLowerCase();
        });
      })
    );
  }

  gotoOpenEditProvider(provider: Provider) {
    this.modal.show(ProviderModalComponent, {
      initialState: {
        provider: {...provider}
      },
      class: 'modal-xl',
      backdrop: 'static'
    });
  }

  openAddProviderModal() {
    this.modal.show(ProviderModalComponent, {
      class: 'modal-xl',
      backdrop: 'static'
    });
  }

  async delete(key: string) {
    if (await AlertService.confirm('¿Estás seguro que deseas eliminar este proveedor?')) {
      this._provider.delete(key);
      AlertService.toastSuccess('Se ha eliminado con éxito');
    }
  }

  decoratedProvidersToExport(providers: Provider[]) {
    return ObjectService.replaceUndefined(providers.map(provider => ({
      name: provider.name,
      lineOfBusiness: provider.lineOfBusiness,
      city: provider.city,
      country: provider.country,
      contact: provider.website
    })));
  }
}
