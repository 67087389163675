import { Component, OnInit } from '@angular/core';
import { PurchaseOrder } from '../../interfaces/purchase-order';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { PurchaseOrderService } from '../../../../../shared/services/purchase-order.service';
import { UserService } from '../../../../../shared/services/user.service';

@Component({
  selector: 'app-purchase-order-modal',
  templateUrl: './purchase-order-modal.component.html',
  styleUrls: ['./purchase-order-modal.component.css']
})
export class PurchaseOrderModalComponent implements OnInit {
  purchaseOrder: PurchaseOrder;

  constructor(public modal: BsModalRef,
    public modalService: BsModalService,
    public _purchase: PurchaseOrderService,
    public _user: UserService,) {
  }

  closeModal() {
    this.modal.hide();
  }

  ngOnInit(): void {
  }

}
