import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Bill } from '../../interfaces/bill';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BillService } from '../../../shared/services/bill.service';
import { AlertService } from '../../../shared/template-services/alert.service';
import { ValidationService } from '../../../shared/template-services/validation.service';
import { formatDate } from '@angular/common';
import { UploadFileStorageComponent } from '../../../shared/template-components/upload-file-storage/upload-file-storage.component';
import { DomSanitizer } from '@angular/platform-browser';
import { FirebaseDataService } from '../../../shared/template-services/firebase-data.service';
import { ProofOfPayment } from '../../interfaces/proof-of-payment';
import { PurchaseOrderService } from '../../../shared/services/purchase-order.service';
import { combineLatest, Subscription } from 'rxjs';
import { PurchaseOrder } from '../../pages/purchase-orders/interfaces/purchase-order';
import { map, take } from 'rxjs/operators';
import { ProviderService } from '../../../shared/services/provider.service';
import { CostCenterType } from '../../enums/cost-center-type.enum';
import { ProjectService } from '../../../shared/services/project.service';
import { PaymentCategory } from '../../interfaces/payment-category';
import { PaymentCategoryService } from '../../services/payment-category.service';
import { PurchaseStatus } from '../../enums/purchase-status.enum';
import { ProjectStatus } from '../../enums/project-status.enum';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { PaymentMethodEnum } from 'src/app/admin/enums/payment-method.enum';
import { PaymentMethodLabel } from 'src/app/admin/labels/payment-method.label';
import { UserType } from '../../enums/user-type.enum';
import { UserService } from 'src/app/shared/services/user.service';
import { Project } from '../../interfaces/project';
import _ from 'lodash';
import { CurrencyFormatPipe } from '../../../shared/pipes/currency-format.pipe';

interface nProject extends Omit<Project, 'type'> {
  type: string;
}

@Component({
  selector: 'app-bill-modal',
  templateUrl: './bill-modal.component.html',
  styleUrls: ['./bill-modal.component.css']
})
export class BillModalComponent implements OnInit, OnDestroy {
  @BlockUI() blockUI: NgBlockUI;
  @ViewChild(UploadFileStorageComponent)
  filesStorage: UploadFileStorageComponent;
  bill: Bill = { key: '' } as Bill;
  billForm: FormGroup;
  submitted: boolean = false;
  isUpdate: boolean = false;
  proofOfPayment = null;
  isPdf: boolean = true;
  url: any;
  urlBill: any;
  purchaseOrdersSubscription: Subscription = new Subscription();
  file: any;
  path: any;
  havePurchaseOrder: boolean = false;
  uploadFile: boolean = false;
  rut: string;
  isSelect: boolean = false;
  provider: string;
  projectSubscription: Subscription = new Subscription();
  itHasUpdated: boolean = false;
  expectPaymentDate: any;
  paymentCategoriesFilter: any[];
  projectExists: boolean = false;
  proofOfPaymentValidate: boolean = false;
  allPurchaseOrders: any = [];
  hasPurchaseOrder: boolean = false;
  isProofOfPaymentSelected: boolean = false;
  projectStatusEnum = ProjectStatus;
  editingDocumentID: boolean = false;
  editingStartDate: boolean = false;
  startDate: number;
  editingProvider: boolean = false;
  providers: string;
  editingExpectedPaymentDate: boolean = false;
  expectedPaymentDate: number;
  editingNetValue: boolean = false;
  netValue: string;
  editingIva: boolean = false;
  iva: string;
  editingTotal: boolean = false;
  total: string;
  editingRealPaymentDate: boolean = false;
  realPaymentDate: number;
  editingPurchaseOrder: boolean = false;
  purchaseOrder: string;
  isUsed: boolean;
  editingModal: boolean = false;
  emptyProject;
  emptyCategory;
  paymentMethodEnum = PaymentMethodEnum;
  paymentMethodLabel = PaymentMethodLabel;
  permission: UserType;
  permissionEnum = UserType;
  categoriesIndexed = {};
  purchaseOrders: PurchaseOrder[] = [];
  projects: nProject[] = [];
  dataSubscription: Subscription = new Subscription();

  constructor(private formBuilder: FormBuilder,
    public modal: BsModalService,
    private _bill: BillService,
    private sanitizer: DomSanitizer,
    private db: FirebaseDataService,
    private _purchase: PurchaseOrderService,
    private _project: ProjectService,
    public _user: UserService) {
    this.createForm();
  }

  createForm() {
    this.billForm = this.formBuilder.group({
      documentNumber: ['', Validators.required],
      startDate: ['', Validators.required],
      provider: ['', Validators.required],
      total: ['', Validators.required],
      purchaseOrder: [''],
      expectedPaymentDate: ['', Validators.required],
      billPdf: ['', Validators.required],
      paymentMethod: [this.paymentMethodEnum.CASH, Validators.required],
      otherPaymentMethod: [''],
      rut: [''],
      netValue: [''],
      iva: [''],
      tax: [''],
      category: [''],
      realPaymentDate: [this.formatDate(new Date().getTime())],
      createdAt: [''],
      isUsed: [false],
      trash: [false],
      isPaid: [false],
      proofOfPayment: [null],
      project: [''],
      projectCategory: ['']
    });
  }

  async ngOnInit() {
    if (this.isUpdate) {
      this.bill = await this._bill.populateObject(this.bill, ['purchaseOrder', 'project', 'projectCategory']) as Bill;
      if (this.bill.proofOfPayment && this.bill.isPaid) this.proofOfPaymentValidate = true;
      if (this.bill.startDate) this.bill.startDate = this.formatDate(this.bill.startDate);
      if (this.bill.expectedPaymentDate) this.bill.expectedPaymentDate = this.formatDate(this.bill.expectedPaymentDate);
      if (this.bill.realPaymentDate) this.bill.realPaymentDate = this.formatDate(this.bill.realPaymentDate);
      if (this.bill.purchaseOrder) this.havePurchaseOrder = true;

      this.bill.proofOfPayment
        ? (this.proofOfPayment = this.bill.proofOfPayment)
        : (this.proofOfPayment = '');

      this.getUrlPdf(this.proofOfPayment, 'proof');

      if (!!this.bill.billPdf) {
        this.getUrlPdf(this.bill.billPdf, 'bill');
      }

      if (!this.bill.expectedPaymentDate) {
        this.bill.expectedPaymentDate = this.formatDate(new Date().getTime());
      }

      this.billForm.patchValue(this.bill);

      this.rut = this.getRut(this.bill.provider);
      this.provider = this.getProvider(this.bill.provider);

      if (this.bill.project) {
        this.projectExists = true;
      }

      this.purchaseOrders = this.purchaseOrders.filter(
        (purchaseOrders) => purchaseOrders.provider.rut == this.rut
      );
    } else {
      this.getData();
    }

    if (!this.billForm.value.startDate) {
      return this.billForm.patchValue({
        startDate: this.formatDate(new Date().getTime()),
        expectedPaymentDate: this.formatDate(new Date().getTime())
      });
    }

    this.billForm.patchValue({ rut: this.rut });

    if (!this.billForm.value.purchaseOrder && !!this.billForm.value.project.name && !!this.billForm.value.projectCategory.name) {
      this.hasPurchaseOrder = false;
    }

    this.validateClassify();
  }

  ngOnDestroy() {
    this.purchaseOrdersSubscription.unsubscribe();
    this.projectSubscription.unsubscribe();
    this.dataSubscription.unsubscribe();
  }

  get formControls() {
    return this.billForm.controls;
  }

  getData() {
    this.blockUI.start('Cargando información...');
    this.dataSubscription = combineLatest([
      this._purchase.getAllAuthorized(),
      this._project.getAll(),
      this._project.getAllCategories()
    ]).subscribe(async ([
      purchaseOrders,
      projects,
      projectCategories
    ]) => {
      this.purchaseOrders = purchaseOrders;
      this.categoriesIndexed = _.keyBy(projectCategories, 'key');
      this.projects = projects.map(project => ({
        ...project,
        type:
          project.type == CostCenterType.PROJECT
            ? 'Proyectos'
            : 'Centro de Costos'
      }));

      if (projects.length > 0) {
        projects = projects.filter(
          (d) =>
            d.status == this.projectStatusEnum.ACTIVE ||
            (d.status == this.projectStatusEnum.INACTIVE &&
              this.convertToTime(d.endDate) >=
              new Date().getTime() - 2629800000)
        );
      }

      if (this.bill?.purchaseOrder) {
        this.bill.purchaseOrder = await this._purchase.get(this.bill.purchaseOrder.key).pipe(take(1)).toPromise();
      }

      if (this.bill?.project) {
        this.projectExists = true;
        let billProject = projects.find((project) => project.key == this.bill.project.id);

        if (billProject) {
          this.billForm.patchValue({ project: billProject });
          this.bill.project = billProject;
        }
      }

      this.allPurchaseOrders = [...this.purchaseOrders];
      this.blockUI.stop();
    });
  }

  getUrlPdf(url: any, document) {
    this.isPdf = url.includes('pdf');
    if (!this.isPdf) {
      return (this.url = url);
    }
    if (document == 'proof') {
      this.url = this.sanitizer.bypassSecurityTrustHtml(
        `<iframe width="100%" height="700" src="${url}"></iframe>`
      );
    }
    if (document == 'bill') {
      this.urlBill = this.sanitizer.bypassSecurityTrustHtml(
        `<iframe width="100%" height="700" src="${url}"></iframe>`
      );
    }
  }

  async confirmOC() {
    if (!this.billForm.value.documentNumber || !this.billForm.value.provider) return AlertService.toastError('Debe asignar un número de factura y un proveedor');

    if (this.hasPurchaseOrder && !this.billForm.value.purchaseOrder) return AlertService.toastError('Debe seleccionar una orden de compra');

    if (
      !this.billForm.value.iva ||
      !this.billForm.value.total ||
      !this.billForm.value.netValue
    ) {
      return AlertService.toastError('Asegurate de que agregar correctamente los datos de detalles');
    }

    if (!this.billForm.value.billPdf) return AlertService.toastError('Debe adjuntar el PDF de la factura');

    if (!this.hasPurchaseOrder && (!this.billForm.value.project || !this.billForm.value.projectCategory)) return AlertService.toastError('Debe seleccionar un proyecto y categoría');

    if (this.billForm.value.isUsed && this.billForm.value.purchaseOrder) return AlertService.toastError('Debe seleccionar un proyecto');

    if (
      (this.billForm.value.paymentMethod == this.paymentMethodEnum.BANK ||
        this.billForm.value.paymentMethod ==
        this.paymentMethodEnum.ELECTRONIC_TRANSFER ||
        this.billForm.value.paymentMethod ==
        this.paymentMethodEnum.PETTY_CASH) &&
      !this.billForm.value.proofOfPayment
    ) {
      return AlertService.toastError('Debe seleccionar un comprobante de pago');
    }

    if (
      this.billForm.value.paymentMethod == this.paymentMethodEnum.OTHER &&
      !this.billForm.value.otherPaymentMethod
    ) {
      return AlertService.toastError('Asegurate de que agregar otra forma de pago');
    }

    if (this.havePurchaseOrder) {
      return this.submit();
    }

    if (this.billForm.value.purchaseOrder && this.billForm.valid) {
      if (
        await AlertService.confirm(
          `¿Estás seguro que quieres confirmar esta factura con la orden de compra ${this.billForm.value.purchaseOrder.purchaseID.slice(0, -3)}?`,
          '')) {
        return this.submit();
      }
    }

    if (!this.billForm.value.purchaseOrder) {
      return this.submit();
    }
  }

  async updateLabels() {
    this.getData();

    this.editingModal = true;
    this.editingExpectedPaymentDate = true;
    this.editingNetValue = true;
    this.editingIva = true;
    this.editingTotal = true;
    this.editingRealPaymentDate = true;
    this.editingPurchaseOrder = true;
  }

  async cancelEditing() {
    const bill = await this._bill.get(this.bill.key).pipe(take(1)).toPromise();
    this.resetEditingMode();
    this.bill = {
      ...bill,
      startDate: this.formatDate(this.bill.startDate),
      expectedPaymentDate: this.formatDate(this.bill.expectedPaymentDate),
      realPaymentDate: this.formatDate(this.bill.realPaymentDate)
    };
  }

  async applyUpdate() {
    if (this.billForm.value.isUsed) {
      this.billForm.get('purchaseOrder').setValidators(Validators.required);
      if (this.needPurchaseOrder()) {
        return AlertService.toastError('Debe seleccionar una orden de compra');
      }
    }

    if (this.needProofOfPayment()) {
      return AlertService.toastError('Debe seleccionar un comprobante de pago');
    }

    this.blockUI.start('Guardando...');
    this.bill = await this._bill.get(this.bill.key).pipe(take(1)).toPromise();

    if (!!this.billForm.value.purchaseOrder) {
      const key = !!this.billForm.value.purchaseOrder.key
        ? this.billForm.value.purchaseOrder.key
        : this.billForm.value.purchaseOrder.id;

      this.billForm.patchValue({
        purchaseOrder: this.db.getReference(`purchaseOrders/${key}`),
        isUsed: true
      });
    }

    if (!!this.billForm.value.project) {
      const key = !!this.billForm.value.project.key
        ? this.billForm.value.project.key
        : this.billForm.value.project.id;

      this.billForm.patchValue({
        project: this.db.getReference(`projects/${key}`)
      });
    }

    await this._bill.update(this.bill.key, {
      ...this.billForm.value,
      proofOfPayment: !this.billForm.value.isPaid
        ? null
        : this.billForm.value.proofOfPayment
    } as Bill);

    await this.setProofPayment();

    await this.cancelEditing();

    this.validateClassify();

    this.blockUI.stop();
    AlertService.toastSuccess('Factura actualizada correctamente');
  }

  async submit() {
    this.submitted = true;
    if (this.billForm.valid) {
      this.blockUI.start('Guardando...');

      if (!this.isUpdate) {
        this.bill.key = `${this.billForm.value.provider}-${this.billForm.value.documentNumber}`;
        this.billForm.patchValue({ createdAt: new Date().getTime() });
      }

      if (this.billForm.value.project) {
        this.billForm.patchValue({
          project: !!this.billForm.value.project.key
            ? this._project.getReference(this.billForm.value.project.key)
            : null,
          isUsed: true
        });
      }
      if (this.billForm.value.projectCategory) {
        if (!!this.billForm.value.projectCategory.reference) {
          this.billForm.patchValue({
            projectCategory: this.db.getReference(
              `projectCategories/${this.billForm.value.projectCategory.reference.id}`
            ),
            isUsed: true
          });
        } else {
          this.billForm.patchValue({
            projectCategory: this.db.getReference(
              `projectCategories/${this.billForm.value.projectCategory.id}`
            ),
            isUsed: true
          });
        }
      }

      if (this.billForm.value.purchaseOrder) {
        if (
          !this.billForm.value.purchaseOrder.invoice ||
          this.billForm.value.purchaseOrder.invoice.length == 0
        ) {
          await this._purchase.update(this.billForm.value.purchaseOrder.key, {
            invoice: [this.db.getReference(`bills/${this.bill.key}`)],
            invoiced:
              this.billForm.value.total >=
              this.billForm.value.purchaseOrder.total - 10 &&
              this.billForm.value.total <=
              this.billForm.value.purchaseOrder.total + 10
          } as PurchaseOrder);
        } else if (this.billForm.value.purchaseOrder.invoice.length > 0) {
          let billsTotalAmount = await this._purchase.getTotalBills(
            this.billForm.value.purchaseOrder.key
          );
          if (!this.isUpdate) {
            this.billForm.value.purchaseOrder.invoice.push(
              this.db.getReference(`bills/${this.bill.key}`)
            );
          }
          await this._purchase.update(this.billForm.value.purchaseOrder.key, {
            ...this.isUpdate ? null : { invoiced: this.validatePurchaseOrderInvoice(billsTotalAmount) },
            invoice: this.billForm.value.purchaseOrder.invoice
          } as PurchaseOrder);
        }

        this.havePurchaseOrder = true;

        this.billForm.patchValue({
          projectCategory: await this._project.getCategory(this.billForm.value.purchaseOrder.budget.id).pipe(take(1)).toPromise(),
          project: await this._project.get(this.billForm.value.purchaseOrder?.project.id).pipe(take(1)).toPromise()
        });
      }

      if (!!this.billForm.value.purchaseOrder) {
        this.billForm.patchValue({
          purchaseOrder: this.db.getReference(`purchaseOrders/${this.billForm.value.purchaseOrder.key}`),
          isUsed: true
        });
      }

      if (this.isSelect) {
        this.proofOfPayment = this.billForm.value.proofOfPayment.content;
        await this._bill.updateProofPayment(
          this.billForm.value.proofOfPayment.key,
          {
            documentNumber: this.billForm.value.documentNumber,
            reference: await this.db.getReference(`bills/${this.bill.key}`),
            name: `Pago - ${this.billForm.value.provider} - ${this.billForm.value.documentNumber}`
          } as ProofOfPayment
        );
      }

      if (
        !this.isSelect &&
        !!this.filesStorage &&
        this.filesStorage.isInputValid
      ) {
        this.proofOfPayment = await this.filesStorage.uploadDocument('vouchers', this.bill.key);
      }

      this.expectPaymentDate = this.billForm.value.expectedPaymentDate;

      this.billForm.patchValue({
        startDate: this.convertToTime(this.billForm.value.startDate),
        expectedPaymentDate: this.convertToTime(this.billForm.value.expectedPaymentDate),
        realPaymentDate: this.convertToTime(this.billForm.value.realPaymentDate),
        proofOfPayment: this.proofOfPayment
      });

      if (this.bill.expectedPaymentDate != this.billForm.value.expectedPaymentDate) this.itHasUpdated = true;

      await this.uploadBillPdf();

      await this._bill.set(this.bill.key, this.billForm.value);

      await this.setProofPayment();

      this.blockUI.stop();

      if (!this.isUpdate) {
        AlertService.toastSuccess(
          this.isUpdate
            ? 'Se actualizó la factura correctamente'
            : 'Se guardó la factura exitosamente'
        );
        this.modal.hide(8177);
      }
      if (this.isUpdate) {
        AlertService.toastSuccess(
          this.isUpdate
            ? 'Se actualizó la factura correctamente'
            : 'Se guardó la factura exitosamente'
        );
      }
    }
  }

  async proofOfPaymentChange() {
    this.blockUI.start('Subiendo Comprobante, favor de esperar...');

    if (
      !this.isSelect &&
      !!this.filesStorage &&
      this.filesStorage.isInputValid
    ) {
      this.proofOfPayment = await this.filesStorage.uploadDocument('vouchers', this.bill.key);
    }

    this.billForm.patchValue({ proofOfPayment: this.proofOfPayment });

    this.billForm.value.proofOfPayment
      ? (this.proofOfPayment = this.billForm.value.proofOfPayment)
      : (this.proofOfPayment = '');

    if (!!this.billForm.value.proofOfPayment) {
      this.getUrlPdf(this.proofOfPayment, 'proof');
    }

    await this._bill.update(this.bill.key, this.billForm.value as Bill);

    this.blockUI.stop();
  }

  async calculatePurchaseOrderTotalBillsAmount(purchaseOrder: PurchaseOrder) {
    if (!purchaseOrder) return;

    const invoices = _.uniqWith(purchaseOrder.invoice, (a: any, b: any) => a.path === b.path);

    const bills = (await Promise.all(invoices.map(async (invoice) =>
      await this._bill.get(invoice.id).pipe(take(1)).toPromise() as Bill
    ))).filter(bill => !!bill);

    return bills.reduce((acc, bill) => {
      return +(bill.total.toString().replace(/[$.]/g, '')) + acc;
    }, 0);
  }

  validatePurchaseOrderInvoice(billsTotalAmount) {
    return (
      billsTotalAmount + +this.billForm.value.total >=
      +this.billForm.value.purchaseOrder.total - 10 &&
      billsTotalAmount + +this.billForm.value.total <=
      +this.billForm.value.purchaseOrder.total + 10
    );
  }

  private formatDate(date) {
    if (!date) {
      return null;
    }
    const deliveryDateDate = new Date(date);
    const format = 'yyyy/MM/dd';
    const locale = 'en-US';
    const zone = 'UTC';
    const formattedDate = formatDate(deliveryDateDate, format, locale, zone);
    date = formattedDate.replace(/\//g, '-');
    return date;
  }

  convertToTime(date) {
    if (date == null) {
      return null;
    }
    let newDeliveryDate = date.replaceAll('-', '/');
    return new Date(newDeliveryDate).getTime();
  }

  updateActualPaymentDate() {
    if (!this.billForm.value.isPaid) {
      return this.billForm.patchValue({
        realPaymentDate: this.formatDate(new Date().getTime())
      });
    }

    if (this.billForm.value.isPaid) {
      return this.billForm.patchValue({
        paymentMethod: this.paymentMethodEnum.CASH
      });
    }

    if (
      (this.billForm.value.isPaid || this.bill.isPaid) &&
      this.permission == this.permissionEnum.USER
    ) {
      AlertService.toastError('No tienes permisos para actualizar el pago');
      return false;
    }

    if (
      !this.billForm.value.isPaid &&
      !(!this.isSelect && !!this.filesStorage && this.filesStorage.isInputValid)
    ) {
      AlertService.toastError('Tienes que adjuntar un pdf antes de pagar');
      return false;
    }
  }

  async chooseFile(event) {
    if (event.target.files[0].name.slice(event.target.files[0].name.length - 3) != 'pdf') {
      return AlertService.error('Solo se permiten archivos pdf');
    }

    if (!ValidationService.validateFileSize(event, 10000)) {
      return AlertService.toastError('El archivo debe ser menor a 10MB');
    }

    this.file = event.target.files[0];

    if (event.target.files && this.file) {
      let reader = new FileReader();
      reader.onload = (event: ProgressEvent) => {
        this.path = (<FileReader>event.target).result;
      };
      reader.readAsDataURL(this.file);
      this.uploadFile = true;
      this.billForm.patchValue({ billPdf: this.file });
    }

    if (!!this.isUpdate) {
      this.blockUI.start('Subiendo Pdf, favor de esperar...');
    }

    //await this.uploadBillPdf();

    this.billForm.patchValue({
      isUsed:
        (!!this.billForm.value.projectCategory &&
          !!this.billForm.value.billPdf) ||
        (!!this.billForm.value.purchaseOrder && !!this.billForm.value.billPdf)
    } as Bill);

    if (!!this.isUpdate) this.blockUI.stop();
  }

  getRut(provider) {
    if (!provider) {
      return '-';
    }
    return provider.substr(0, provider.indexOf(' '));
  }

  getProvider(provider) {
    if (!provider) {
      return '-';
    }
    return provider.substr(provider.indexOf(' ') + 1);
  }

  setRealPaymentDate() {
    this.billForm.patchValue({
      realPaymentDate: this.billForm.value.expectedPaymentDate
    });
  }

  async getFilterCategories() {
    if (this.billForm.value.project?.budgets.length < 1) {
      this.paymentCategoriesFilter = [];
      return;
    }

    if (
      !!this.billForm.value &&
      !this.billForm.value.project?.budgets[0].name
    ) {
      return (this.paymentCategoriesFilter =
        this.billForm.value.project?.budgets.map((budget) => ({
          ...budget,
          ...this.categoriesIndexed[budget.reference.id]
        })));
    }

    this.paymentCategoriesFilter = this.billForm.value.project?.budgets;
  }

  validateClassify() {
    this.billForm.patchValue({
      isUsed:
        (!!this.billForm.value.projectCategory &&
          !!this.billForm.value.billPdf) ||
        (!!this.billForm.value.purchaseOrder && !!this.billForm.value.billPdf)
    } as Bill);

    this._bill.update(this.bill.key, {
      isUsed:
        (!!this.billForm.value.projectCategory &&
          !!this.billForm.value.billPdf) ||
        (!!this.billForm.value.purchaseOrder && !!this.billForm.value.billPdf)
    } as Bill);
  }

  deleteProofOfPayment() {
    this.billForm.patchValue({ proofOfPayment: '' });
    this.isProofOfPaymentSelected = false;
  }

  async handleChangePurchaseOrder() {
    if (!!this.billForm.value.purchaseOrder && this.hasPurchaseOrder && (this.billForm.value.category == '' || this.billForm.value.projectCategory == '')) {
      this.emptyCategory = true;
    }

    if (!!this.billForm.value.purchaseOrder && this.hasPurchaseOrder && (this.billForm.value?.project?.name == '' || this.billForm.value?.project == '' || this.billForm.value?.project?.id == 'undefined')) {
      this.emptyProject = true;
    }

    const totalUsed = +(await this._purchase.getTotalBills(this.billForm.value.purchaseOrder.key)) + +this.billForm.value.total;
    if (totalUsed > +this.billForm.value.purchaseOrder.total + 10) {
      const total = +this.billForm.value.purchaseOrder.total;

      this.billForm.patchValue({ purchaseOrder: null });
      return await AlertService.error(
        'Ha excedido el total de la orden de compra',
        `Total de de compra: ${CurrencyFormatPipe.prototype.transform(total)}. Total de facturas: ${CurrencyFormatPipe.prototype.transform(totalUsed)}`
      );
    }

    this.billForm.patchValue({
      project: this.billForm.value.purchaseOrder.project,
      projectCategory: this.billForm.value.purchaseOrder.budget

    });
  }

  switchPurchaseOrder() {
    this.hasPurchaseOrder = !this.hasPurchaseOrder;
    if (!this.hasPurchaseOrder) {
      this.billForm.get('projectCategory').setValidators(Validators.required);
      this.billForm.get('project').setValidators(Validators.required);
      this.billForm.get('purchaseOrder').clearValidators();
    } else {
      this.billForm.get('projectCategory').clearValidators();
      this.billForm.get('project').clearValidators();
      this.billForm.get('purchaseOrder').setValidators(Validators.required);
      if (
        !!this.billForm.value.purchaseOrder &&
        (this.billForm.value.category == '' ||
          this.billForm.value.projectCategory == '')
      ) {
        this.emptyCategory = true;
      }
      if (
        !!this.billForm.value.purchaseOrder &&
        (this.billForm.value.project.name == '' ||
          this.billForm.value.project == '' ||
          this.billForm.value.project.id == 'undefined')
      ) {
        this.emptyProject = true;
      }
    }

    this.billForm.patchValue({
      purchaseOrder: '',
      project: '',
      projectCategory: ''
    });
  }

  validatePaymentMethod() {
    if (
      this.billForm.value.paymentMethod == this.paymentMethodEnum.BANK ||
      this.billForm.value.paymentMethod ==
      this.paymentMethodEnum.ELECTRONIC_TRANSFER ||
      this.billForm.value.paymentMethod == this.paymentMethodEnum.PETTY_CASH
    ) {
      this.billForm.get('proofOfPayment').setValidators(Validators.required);
    } else {
      this.billForm.get('proofOfPayment').clearValidators();
    }

    if (this.billForm.value.paymentMethod == this.paymentMethodEnum.OTHER) {
      this.billForm
        .get('otherPaymentMethod')
        .setValidators(Validators.required);
    }
  }

  handleFindPurchaseOrder() {
    this.purchaseOrders = this.allPurchaseOrders.filter(
      (purchase) =>
        purchase.provider.rut == this.billForm.value.provider.split(' ')[0]
    );
  }

  private needProofOfPayment() {
    return (
      (this.billForm.value.paymentMethod == this.paymentMethodEnum.BANK ||
        this.billForm.value.paymentMethod ==
        this.paymentMethodEnum.ELECTRONIC_TRANSFER ||
        this.billForm.value.paymentMethod ==
        this.paymentMethodEnum.PETTY_CASH) &&
      !(!this.isSelect && !!this.filesStorage && this.filesStorage.isInputValid)
    );
  }

  private needPurchaseOrder() {
    return !this.billForm.value.purchaseOrder && this.hasPurchaseOrder;
  }

  private resetEditingMode() {
    this.editingModal = false;
    this.editingExpectedPaymentDate = false;
    this.editingNetValue = false;
    this.editingIva = false;
    this.editingTotal = false;
    this.editingRealPaymentDate = false;
    this.editingPurchaseOrder = false;
  }

  private async setProofPayment() {
    if (!!this.filesStorage && this.filesStorage.isInputValid) {
      let proofOfPayment: ProofOfPayment = {
        documentNumber: this.billForm.value.documentNumber,
        name: this.filesStorage.fileToUpload.name,
        proofOfPayment: this.proofOfPayment,
        reference: this.db.getReference(`bills/${this.bill.key}`),
        trash: false,
        type: 0
      };

      await this._bill.setProofPayment(this.bill.key, proofOfPayment);
    }
  }

  private async uploadBillPdf() {
    if (this.uploadFile) {
      this.billForm.patchValue({
        billPdf: await this._bill.uploadBillPdf(
          this.billForm.value.billPdf,
          this.bill.key
        )
      });
    }

    if (!!this.billForm.value.billPdf) {
      this.getUrlPdf(this.billForm.value.billPdf, 'bill');
    }
  }
}
