import { Component, OnInit } from '@angular/core';
import { ProviderArticle } from '../../interfaces/provider-article';
import { from, Observable, of, Subscription } from 'rxjs';
import { DataTableConfig } from 'src/app/shared/interfaces/data-table-config';
import { ProviderArticleService } from '../../services/provider-article.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ProviderArticleComponent } from '../../modals/provider-article/provider-article.component';
import { ProviderService } from '../../../shared/services/provider.service';
import { UnitService } from '../../services/unit.service';
import { map, mergeMap } from 'rxjs/operators';
import { ObjectService } from '../../../shared/template-services/object.service';
import moment from 'moment';
import _ from 'lodash';
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: 'app-providers-articles',
  templateUrl: './providers-articles.component.html',
  styleUrls: ['./providers-articles.component.css']
})
export class ProvidersArticlesComponent implements OnInit {
  articles$: Observable<ProviderArticle[]>;
  articlesFiltered$: Observable<ProviderArticle[]>;
  config: DataTableConfig = {
    hasSearch: true,
    notFoundText: 'No se encontraron artículos',
    title: '',
    exportCallback: this.decorateProvidersArticlesToExport.bind(this),
    excelFileName: 'Artículos de proveedores',
    pagination: true,
    paginationLimit: 25,
    removeInfiniteScroll: true
  };

  providers: any[] = [];
  purchaseOrders: any[] = [];
  providerSelected: any;
  purchaseOrderSelected: any;
  articlesSubscription: Subscription = new Subscription();
  showAll: boolean = false;
  dateFilter = moment().subtract(1, 'year').valueOf();

  constructor(private _providerArticle: ProviderArticleService,
    private modal: BsModalService,
    private _provider: ProviderService,
    private _unit: UnitService,
    private spinner: NgxSpinnerService) {
  }

  async ngOnInit(): Promise<void> {
    this.getArticlesDataFromAPI(this.dateFilter);
  }

  getArticlesDataFromAPI(fromDate?: number) {
    this.spinner.show();
    this.articlesSubscription = this._providerArticle.getArticlesDataFromAPI(fromDate).subscribe(({
      articles,
      providers,
      purchaseOrders
    }) => {
      this.articles$ = of(articles);

      this.providers = providers.map(provider => ({
        value: provider.name,
        label: provider.name
      }));

      this.purchaseOrders = purchaseOrders.map(purchaseOrder => {
        if(!purchaseOrder?.purchaseID) console.info(purchaseOrder);
        return ({
          value: purchaseOrder.purchaseID,
          label: purchaseOrder ? `OC ${(purchaseOrder?.purchaseID || '').slice(0, -3)}` : '',
        })
      });

      this.articlesFiltered$ = this.articles$;
      this.spinner.hide();
    });
  }

  openArticle(article: any) {
    this.modal.show(ProviderArticleComponent, {
      initialState: {
        article
      },
      class: 'modal-sm'
    });
  }

  filterArticles() {
    this.articlesFiltered$ = this.articles$.pipe(
      map(articles => {
        return articles.filter((article: any) => {
          return (!this.providerSelected || article.provider.name === this.providerSelected) &&
            (!this.purchaseOrderSelected || (article.purchaseOrder ? article.purchaseOrder.purchaseID === this.purchaseOrderSelected : false));
        });
      })
    );
  }

  decorateProvidersArticlesToExport(rows: any[]) {
    return ObjectService.replaceUndefined(rows.map(row => {
      if(!row.purchaseOrder?.purchaseID) console.info(row.purchaseOrder);
      return ({
        name: row.name,
        providerCode: row.providerCode,
        provider: row.provider ? row.provider.name : 'No registrado',
        unitType: row.unitType ? row.unitType.name : '',
        unitPrice: row.unitPrice,
        exempt: row.exempt ? 'Si' : 'No',
        purchaseOrder: row.purchaseOrder ? `OC ${(row.purchaseOrder?.purchaseID || '').slice(0, -3)}` : '',
        createdAt: moment(row.createdAt).format('DD/MM/YYYY HH:mm')
      })
    }));
  }

  handleDateFilter(type: 'all' | 'lastYear') {
    if ((type === 'all' && this.showAll) || (type == 'lastYear' && !this.showAll)) return;

    if (type === 'all') {
      this.getArticlesDataFromAPI();
      this.filterArticles();
      this.showAll = true;
    } else {
      this.getArticlesDataFromAPI(this.dateFilter);
      this.filterArticles();
      this.showAll = false;
    }
  }
}
