export const environment = {
  production: true,
  firebase: {
    apiKey: 'AIzaSyCJjFnqoU-NRUFoj020u0q3CIpziKbSARs',
    authDomain: 'plataforma.colsan.cl',
    databaseURL: 'https://colsan-prod.firebaseio.com',
    projectId: 'colsan-prod',
    storageBucket: 'colsan-prod.appspot.com',
    messagingSenderId: '381719991665',
    appId: '1:381719991665:web:64bc491c38af7bb42a92d0',
    measurementId: 'G-EWF95ZL6L7',
  },
  googleApiKey: 'AIzaSyAIIYOxA7qeetFz6TuR1Qewc0Rrjhzx7ZU',
  apiBaseURL: 'https://us-central1-colsan-prod.cloudfunctions.net',
  apiBaseURLV1: 'https://us-central1-colsan-prod.cloudfunctions.net/api',
  apiReportUrlV1: 'https://us-central1-tintech-app.cloudfunctions.net/api',
  projectKey: 'nTuVqSTnYFNBUSqPZxKUVr3hr0o1',
  embeddedUrl: 'https://embedded-colsan.web.app',
  hmr: false,
  version: 'v 1.18.2',
  replaceUID: ''
};
