<div *ngIf="showAlert" class="alert alert-warning alert-dismissible fade show" role="alert">
  <div class="d-flex align-items-center gap-2">
    <div style="margin-right: .8rem;">
      <strong>¡Estás visualizando la versión 2 de tableros!</strong> Para visualizar la versión anterior de los tableros, por favor, haga clic en el siguiente botón.
    </div>
    <button type="button" class="btn-sm btn-primary" (click)="showBoardsV1()">Tablero v1</button>
  </div>
  <button type="button" class="close" data-dismiss="alert" aria-label="Close" (click)="closeAlert()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<iframe
  *ngIf="boardsUrl"
  [src]="boardsUrl | safe"
  allow="camera; clipboard-write; clipboard-read"
  style="border: 0; width: 100%; height: calc(100vh - 145px); background-color: transparent !important;"></iframe>
