import { Component, OnInit } from '@angular/core';
import { UserService } from '../../../shared/services/user.service';
import { Location } from '@angular/common';
import { environment } from '../../../../environments/environment';
import { Router } from '@angular/router';

@Component({
  selector: 'app-boardsv2',
  templateUrl: './boardsv2.component.html',
  styleUrls: ['./boardsv2.component.css']
})
export class Boardsv2Component implements OnInit {
  boardsUrl;
  baseUrl: string = '/admin/boards-v2';
  showAlert: boolean = true;

  constructor(
    public _user: UserService, private location: Location, private router: Router
  ) {
  }

  ngOnInit(): void {
    const alertClosed = sessionStorage.getItem('alertClosed');
    this.showAlert = !JSON.parse(alertClosed);

    this._user.getValidationToken().then(token => {
      token = btoa(token);

      let dataParam = window.location.href.includes('data=') ? window.location.href.split('data=')[1] : '';
      dataParam = dataParam ? `&data=${dataParam}` : '';

      this.boardsUrl = `${environment.embeddedUrl}/#/tasks?uid=${this._user.user.key}&token=${token}${dataParam}`;
    });

    window.addEventListener('message', (event) => {
      if (event.origin !== environment.embeddedUrl) return;

      this.location.replaceState(event.data ? `${this.baseUrl}?data=${event.data}` : this.baseUrl);
    }, false);
  }

  closeAlert(): void {
    this.showAlert = false;
    sessionStorage.setItem('alertClosed', 'true');
  }

  showBoardsV1(): void {
    this.router.navigate(['/admin/boards']);
  }
}
