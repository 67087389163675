import { Component, OnInit } from '@angular/core';
import { UserService } from '../../../shared/services/user.service';
import { environment } from '../../../../environments/environment';
import { Location } from '@angular/common';

@Component({
  selector: 'app-fund-to-render',
  templateUrl: './fund-to-render.component.html',
  styleUrls: ['./fund-to-render.component.css']
})
export class FundToRenderComponent implements OnInit {
  fundToRenderUrl;
  baseUrl: string = '/admin/fund-to-render-v2';

  constructor(public _user: UserService,
    private location: Location) {
  }

  ngOnInit(): void {
    this._user.getValidationToken().then(token => {
      token = btoa(token);

      let dataParam = window.location.href.includes('data=') ? window.location.href.split('data=')[1] : '';
      dataParam = dataParam ? `&data=${dataParam}` : '';

      this.fundToRenderUrl = `${environment.embeddedUrl}/#/funds-to-render?uid=${this._user.user.key}&token=${token}${dataParam}`;
    });

    window.addEventListener('message', (event) => {
      if (event.origin !== environment.embeddedUrl) return;

      this.location.replaceState(event.data ? `${this.baseUrl}?data=${event.data}` : this.baseUrl);
    }, false);
  }
}
