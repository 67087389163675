import { Component, OnInit } from '@angular/core';
import { UserService } from "../../../shared/services/user.service";
import { Location } from "@angular/common";
import { environment } from "../../../../environments/environment";

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  blogUrl;
  baseUrl: string = '/admin/blog';

  constructor(public _user: UserService,
    private location: Location) {
  }

  ngOnInit(): void {
    this._user.getValidationToken().then(token => {
      token = btoa(token);

      let dataParam = window.location.href.includes('data=') ? window.location.href.split('data=')[1] : '';
      dataParam = dataParam ? `&data=${dataParam}` : '';

      this.blogUrl = `${environment.embeddedUrl}/#/blog?uid=${this._user.user.key}&token=${token}${dataParam}`;
    });

    window.addEventListener('message', (event) => {
      if (event.origin !== environment.embeddedUrl) return;

      this.location.replaceState(event.data ? `${this.baseUrl}?data=${event.data}` : this.baseUrl);
    }, false);
  }

}
