import { Component, AfterViewInit } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { UserService } from '../../../shared/services/user.service';
import { Router } from '@angular/router';
import { Location } from '@angular/common';

@Component({
  selector: 'app-projects-v2',
  templateUrl: './projects-v2.component.html',
  styleUrls: ['./projects-v2.component.css']
})
export class ProjectsV2Component implements AfterViewInit {
  projectsUrl: string;
  baseUrl: string = '/admin/projects';

  constructor(
    public _user: UserService,
    private router: Router,
    private location: Location
  ) {
  }

  getMessageData = (event) => {
    if (event.origin !== environment.embeddedUrl) return;

    let data = event.data;
    if (data) data = JSON.parse(atob(data));

    if (data) {
      if (data.parentPath) {
        const queryParams = { data: event.data };
        this.router.navigate(['/admin/' + data.parentPath], { queryParams });
      } else {
      this.location.replaceState(event.data ? `${this.baseUrl}?data=${event.data}` : this.baseUrl);
    }
    } else {
      this.location.replaceState(this.baseUrl);
    }
  };

  ngAfterViewInit(): void {
    this._user.getValidationToken().then(token => {
      token = btoa(token);

      let dataParam = window.location.href.includes('data=') ? window.location.href.split('data=')[1] : '';
      dataParam = dataParam ? `&data=${dataParam}` : '';

      this.projectsUrl = `${environment.embeddedUrl}/#/projects?uid=${this._user.user.key}&token=${token}${dataParam}`;
    });

    window.addEventListener('message', this.getMessageData, false);
  }

  ngOnDestroy() {
    window.removeEventListener('message', this.getMessageData, false);
  }
}
