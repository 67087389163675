import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UserService } from '../../../shared/services/user.service';
import { environment } from '../../../../environments/environment';
import { Subscription } from 'rxjs';
import { NavigationEnd, Router } from '@angular/router';
import { Location } from '@angular/common';

@Component({
  selector: 'app-sales-v2',
  templateUrl: './sales-v2.component.html',
  styleUrls: ['./sales-v2.component.css']
})
export class SalesV2Component implements AfterViewInit, OnDestroy {
  @ViewChild('iframe', { static: false }) iframe!: ElementRef;
  firstLoad = true;
  peopleManagementUrl: string;
  routeSubscription: Subscription;
  baseUrl: string = '/admin/sales-v2';

  constructor(private _user: UserService,
    private router: Router,
    private location: Location) {
    this.getMessageData = this.getMessageData.bind(this);
  }

  setUrl(url: string) {
    this._user.getValidationToken().then(token => {
      token = btoa(token);
      let dataParam = url.includes('data=') ? url.split('data=')[1] : '';
      if (dataParam) dataParam = `&data=${dataParam}`;

      this.peopleManagementUrl = `${environment.embeddedUrl}/#/sales?uid=${this._user.user.key}&token=${token}${dataParam}`;
      this.firstLoad = false;
    });
  }

  getMessageData(event) {
    if (event.origin !== environment.embeddedUrl) return;

    let data = event.data;
    if (data) data = JSON.parse(atob(data));

    if (data) {
      if (data.parentPath) {
        const queryParams = { data: event.data };
        this.router.navigate(['/admin/' + data.parentPath], { queryParams });
      } else {
        this.location.replaceState(event.data ? `${this.baseUrl}?data=${event.data}` : this.baseUrl);
      }
    } else {
      this.location.replaceState(this.baseUrl);
    }
  }

  ngAfterViewInit(): void {
    if (this.firstLoad) this.setUrl(window.location.href);

    window.addEventListener('message', this.getMessageData, false);
  }

  ngOnDestroy() {
    window.removeEventListener('message', this.getMessageData, false);
  }
}
