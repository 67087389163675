// Default menu settings configurations

export interface MenuItem {
  title: string;
  icon: string;
  page: string;
  isHidden?: boolean;
  isExternalLink?: boolean;
  issupportExternalLink?: boolean;
  isStarterkitExternalLink?: boolean;
  badge: { type: string; value: string };
  submenu: {
    items: Partial<MenuItem>[];
  };
  section: string;
}

export interface MenuConfig {
  horizontal_menu: {
    items: Partial<MenuItem>[];
  };
  vertical_menu: {
    items: Partial<MenuItem>[];
  };
}

export const MenuSettingsConfig: MenuConfig = {
  horizontal_menu: {
    items: []
  },
  vertical_menu: {
    items: [
      {
        title: 'Blog',
        icon: 'la-newspaper-o',
        page: '/admin/blog'
      },
      {
        title: 'Notificaciones',
        icon: 'la-bell',
        page: '/admin/notifications'
      },
      {
        title: 'Usuarios',
        icon: 'la-users',
        page: '/admin/users'
      },
      {
        title: 'Ventas',
        icon: 'la-tag',
        page: '/admin/sales-v2',
      },
      {
        title: 'Bodegas',
        icon: 'la-building',
        page: 'null',
        submenu: {
          items: [
            {
              title: 'Lista de bodegas',
              icon: 'la-building',
              page: '/admin/warehouse'
            },
            {
              title: 'Categorías',
              icon: 'la-object-group',
              page: '/admin/categories'
            },
            {
              title: 'Registros de usuarios',
              icon: 'la-user',
              page: '/admin/warehouse/users'
            },
            {
              title: 'Detalles',
              icon: 'la-object-group',
              page: '/admin/warehouseDetails',
              isHidden: true
            }
          ]
        }
      },
      {
        title: 'Proyectos',
        icon: 'la-folder',
        page: '/admin/projects',
      },
      {
        title: 'Gestión de personas',
        icon: 'feather ft-users',
        page: 'null',
        submenu: {
          items: [
            {
              title: 'Selección de personal',
              page: 'null',
              submenu: {
                items: [
                  {
                    title: 'Lista general',
                    page: '/admin/people-management/applicants'
                  },
                  {
                    title: 'Postulantes',
                    page: '/admin/people-management/applicants/postulante'
                  },
                  {
                    title: 'CV válidos',
                    page: '/admin/people-management/applicants/cv-valido'
                  },
                  {
                    title: 'Entrevistados',
                    page: '/admin/people-management/applicants/entrevistado'
                  },
                  {
                    title: 'Calificados',
                    page: '/admin/people-management/applicants/calificado'
                  }
                ]
              }
            },
            {
              title: 'Reclutamiento',
              page: 'null',
              submenu: {
                items: [
                  {
                    title: 'Lista general',
                    page: '/admin/people-management/recruitment'
                  },
                  {
                    title: 'Tablero',
                    page: '/admin/people-management/recruitment/tab-board'
                  },
                  {
                    title: 'Correo enviado',
                    page: '/admin/people-management/recruitment/correo-enviado'
                  },
                  {
                    title: 'Correo aceptado',
                    page: '/admin/people-management/recruitment/correo-aceptado'
                  },
                  {
                    title: 'En exámenes',
                    page: '/admin/people-management/recruitment/en-examenes'
                  },
                  {
                    title: 'Exámenes aprobados',
                    page: '/admin/people-management/recruitment/examenes-aprobados'
                  },
                  {
                    title: 'En inducción a cliente',
                    page: '/admin/people-management/recruitment/induccion-cliente'
                  },
                  {
                    title: 'En inducción a Colsan',
                    page: '/admin/people-management/recruitment/induccion-colsan'
                  },
                  {
                    title: 'En contratación',
                    page: '/admin/people-management/recruitment/en-contratacion'
                  }
                ]
              }
            },
            {
              title: 'Contratados',
              page: '/admin/people-management/hired'
            },
            {
              title: 'Inactivos',
              page: '/admin/people-management/inactive'
            },
            {
              title: 'Calendario',
              page: '/admin/people-management/calendar'
            },
            {
              title: 'Configuración',
              page: 'null',
              submenu: {
                items: [
                  {
                    title: 'Cargos',
                    page: '/admin/positions'
                  },
                  {
                    title: 'Cuestionarios',
                    page: '/admin/quiz'
                  },
                  {
                    title: 'Estatus',
                    page: '/admin/status'
                  },
                  {
                    title: 'Afp',
                    page: '/admin/afp'
                  },
                  {
                    title: 'Correo de preselección',
                    page: '/admin/people-management/email-templates/tab-preselection'
                  },
                  {
                    title: 'Correo exámenes',
                    page: '/admin/people-management/email-templates/tab-exams'
                  },
                  {
                    title: 'Horarios',
                    page: '/admin/people-management/schedules'
                  },
                  {
                    title: 'Filtros',
                    page: '/admin/people-management/filters'
                  },
                  {
                    title: 'Tipos de eventos',
                    page: '/admin/people-management/event-types'
                  },
                  {
                    title: 'Tipos de exámenes',
                    page: '/admin/people-management/exam-types'
                  },
                  {
                    title: 'Habilidades',
                    page: '/admin/people-management/skills'
                  }
                ]
              }
            }
          ]
        }
      },
      {
        title: 'Compras',
        icon: 'feather ft-shopping-cart',
        page: 'null',
        submenu: {
          items: [
            {
              title: 'Lista',
              page: '/admin/purchase-orders'
            },
            // {
            //   title: 'Artículos por llegar',
            //   page: '/admin/articles-to-come',
            // },
            {
              title: 'Proveedores',
              page: '/admin/providers'
            },
            {
              title: 'Artículos',
              page: '/admin/providerArticles'
            },
            {
              title: 'Unidades',
              page: '/admin/units'
            },
            {
              title: 'Configuración',
              page: '/admin/id-settings'
            }
          ]
        }
      },
      {
        title: 'Tesorería',
        icon: 'la-money',
        page: 'null',
        submenu: {
          items: [
            {
              title: 'Facturas',
              page: '/admin/bills'
            },
            {
              title: 'Comprobantes de pago',
              page: '/admin/proofPayments'
            },
            {
              title: 'Sueldos',
              page: '/admin/workerSalaries'
            },
            {
              title: 'Gastos totales',
              page: '/admin/totalExpenses'
            },
            {
              title: 'Reportes',
              page: '/admin/reports'
            },
            {
              title: 'Reportes por proyectos',
              page: '/admin/project-invoice-reports'
            }
          ]
        }
      },
      {
        title: 'Tareas',
        icon: 'la-list',
        page: '/admin/boards-v2'
      },
      {
        title: 'Fondos por rendir',
        icon: 'la-exchange',
        page: '/admin/fund-to-render'
      },
      {
        title: 'Flota',
        icon: 'la-car',
        page: '/admin/vehicles'
      }
    ]
  }
};
